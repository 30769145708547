import * as Sentry from '@sentry/nextjs';
import { DatabaseError, IPromptsWithTagsData } from '@shared/util';
import {
  PostgrestError,
  PostgrestResponse,
  SupabaseClient,
} from '@supabase/supabase-js';

export const createPrompt = async (
  supabase: SupabaseClient,
  userUUID: string,
  data: {
    name: string;
    prompt: string;
    tags: string[];
  }
) => {
  const captureException = (error: PostgrestError) =>
    Sentry.captureException(
      new DatabaseError({
        category: 'Supabase',
        code: 'DB0005',
        details: error,
        message: 'Error on creating prompt',
      })
    );

  const { data: promptData, error: promptError } = await supabase
    .from('prompts')
    .insert({
      content: [
        {
          data: data.prompt,
        },
      ],
      name: data.name,
      user_id: userUUID,
    })
    .select()
    .single();

  if (promptError) {
    captureException(promptError);
    return { data: promptData, error: true };
  }

  if (!data.tags) return { data: promptData, error: false };

  const promptTags = data.tags.map((tagId) => ({
    prompt_id: promptData.id,
    tag_id: tagId,
  }));

  const { error: promptTagsError } = await supabase
    .from('prompt_tags')
    .upsert(promptTags);

  if (promptTagsError) captureException(promptTagsError);

  return { data: promptData, error: !!promptTagsError };
};

export const getPrompts = async (
  supabase: SupabaseClient,
  userUUID: string,
  tagsIds?: string | null
) => {
  const { data, error } = tagsIds
    ? ((await supabase
        .from('prompts')
        .select('id, name, updated_at, content, tags(id, name)')
        .eq('user_id', userUUID)
        .filter('tags.id', 'in', tagsIds)
        .order('updated_at', {
          ascending: false,
        })) as PostgrestResponse<IPromptsWithTagsData>)
    : ((await supabase
        .from('prompts')
        .select('id, name, updated_at, content, tags(id, name)')
        .eq('user_id', userUUID)
        .order('updated_at', {
          ascending: false,
        })) as PostgrestResponse<IPromptsWithTagsData>);

  if (error) {
    Sentry.captureException(
      new DatabaseError({
        category: 'Supabase',
        code: 'DB0005',
        details: error,
        message: 'Error on retrieving prompts',
      })
    );
  }

  return { data, error };
};
