import { Box } from '@chakra-ui/react';

import { useTags } from '@shared/data';

import { Tag } from '../Tag/Tag';

export interface ITagsListProps {
  textEmpty: string;
}

export function TagsList(props: ITagsListProps) {
  const { textEmpty } = props;

  const { selectedTags, tags } = useTags();
  const isEmpty = !tags || tags.length === 0;

  return (
    <Box
      bg="containerBackground.500"
      borderRadius={16}
      boxShadow="12px 12px 24px #cbced1, -12px -12px 24px #ffffff;"
      maxW={900}
      minW={{ base: '0', lg: 600 }}
      p={10}
    >
      {isEmpty ? (
        <p>{textEmpty}</p>
      ) : (
        tags.map((tag) => (
          <Tag
            key={tag.id}
            selected={selectedTags.includes(tag.name)}
            tag={tag}
          />
        ))
      )}
    </Box>
  );
}

export default TagsList;
