import { Heading, Link, List, ListItem, Text } from '@chakra-ui/react';
import React from 'react';

export const PrivacyContentFr = () => (
  <>
    <Heading as="h2" size="md">
      Qui sommes-nous ?
    </Heading>
    <Text>
      La plateforme <span className="bold">GPT TOOL BOX</span> est hébergée sur
      l'adresse{' '}
      <Link href="https://gpt-tool-box.fr" isExternal>
        https://toolbox-gpt.com
      </Link>{' '}
      , service développé et propriété de la Société{' '}
      <Link href="https://online-platforms.fr" isExternal>
        Online Platforms
      </Link>
      .
    </Text>
    <Heading as="h2" size="md">
      Responsable du traitement des données
    </Heading>
    <Text>
      La SAS Online Platforms traite les données vous concernant et est
      responsable du traitement des données personnelles collectées sur notre
      site.
    </Text>
    <Heading as="h2" size="md">
      Quelles données collectées et dans quel but ?
    </Heading>
    <Text>
      Votre navigateur Internet transmet automatiquement les informations
      suivantes dès votre connexion , informations que nous collectons :
    </Text>
    <List styleType="disc">
      <ListItem>
        Le système d’exploitation de votre terminal (Windows, Mac, Androïd, iOS,
        …) : analyse et affichage
      </ListItem>
      <ListItem>
        Le navigateur Internet utilisé et sa langue d’utilisation :
        personnalisation et analyse
      </ListItem>
      <ListItem>Votre adresse IP : analyse statistique</ListItem>
      <ListItem>
        L’heure à laquelle la visite a été effectuée : nous permet de prévoir la
        période où il y a le moins d’affluence pour faire les mises à jour et
        maintenance
      </ListItem>
      <ListItem>
        Des informations techniques sur votre type de terminal (ordinateur,
        smartphone, tablette, …) : analyse et affichage
      </ListItem>
      <ListItem>
        Le site Internet depuis lequel vous avez été redirigé vers notre site
        (si tel est le cas) : analyse statistique
      </ListItem>
    </List>
    <Text>
      En fonction des actions menées sur le site, vous pourrez être amené à
      indiquer des informations personnelles vous concernant :
    </Text>
    <List as="ul">
      <ListItem>Votre adresse mail : nécessaire pour vous répondre</ListItem>
      <ListItem>
        Votre n° de téléphone : si vous souhaitez qu’on vous rappelle
      </ListItem>
      <ListItem>
        Vos nom et prénom : pour traiter votre demande et nous assurer que vous
        êtes bien une personne physique
      </ListItem>
    </List>
    <Text>
      Ces informations seront collectées pour traiter votre demande et seront
      conservées jusqu’à ce que vous en demandiez leur effacement ou sous un
      délai maximum de 3 ans après le dernier contact. De même, vous pouvez
      demander à avoir accès aux informations personnelles collectées vous
      concernant, votre opposition ou rectification.
    </Text>
    <Text>
      De même, vous pouvez demander à avoir accès aux informations personnelles
      collectées vous concernant, votre opposition ou rectification.
    </Text>
    <Heading as="h2" size="md">
      Qui a accès à vos données ?
    </Heading>
    <Text>
      La SAS Online Platforms ne divulgue, ni ne commercialise vos données
      personnelles à des entreprises externes.
    </Text>
    <Text>
      Nous pouvons avoir recours à des prestataires externes pour l’hébergement
      et le stockage des données et pour le bon fonctionnement de nos services.{' '}
    </Text>
    <Text>
      Nous pouvons avoir recours à des prestataires externes pour l’hébergement
      et le stockage des données et pour le bon fonctionnement de nos services.
    </Text>
    <Heading as="h2" size="md">
      Combien de temps sont conservées vos données ?
    </Heading>
    <Text>
      Les données seront conservées 3 ans après le dernier contact à des fins de
      communication essentiellement.
    </Text>
    <Heading as="h2" size="md">
      Droit d’accès et de rectification des données transmises
    </Heading>
    <Text>
      Comme prévu dans le cadre de la RGPD, toute personne peut demander à avoir
      accès, rectifier, s’opposer ou effacer ses données personnelles.
    </Text>
    <Text>
      Une simple demande par mail à{' '}
      <a href="mailto:contact@online-platforms.fr">
        contact@online-platforms.fr
      </a>{' '}
      est nécessaire pour accéder à votre demande, avec justificatifs éventuels
      en fonction de la demande.
    </Text>
    <Heading as="h2" size="md">
      Cookies
    </Heading>
    <Text>
      La navigation sur notre site entraîne l’installation de cookies sur
      l’ordinateur de l’utilisateur, sauf avis contraire de la part de
      l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet
      pas l’identification de l’utilisateur, mais qui enregistre des
      informations relatives à la navigation d’un ordinateur sur un site. Les
      données ainsi obtenues visent à faciliter la navigation ultérieure sur le
      site, et ont également vocation à permettre diverses mesures de
      fréquentation.
    </Text>
    <Text>
      Le refus d’installation d’un cookie peut entraîner l’impossibilité
      d’accéder à certains services. L’utilisateur peut toutefois configurer son
      ordinateur pour refuser l’installation des cookies.
    </Text>
    <Heading as="h2" size="md">
      Liens hypertextes
    </Heading>
    <Text>
      Des liens hypertextes vers d’autres sites sont présents sur notre site,
      avec notre accord. Cependant, nous n’avons pas la possibilité de vérifier
      le contenu des sites ainsi visités, et nous n’assumerons en conséquence
      aucune responsabilité de ce fait.
    </Text>
  </>
);
