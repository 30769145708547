import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import { useRouter } from 'next/router';
import { useTheme } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { useUser } from '@clerk/nextjs';

import { Price, ProductWithPrice } from '@shared/util';

import { ProductPrice } from '../ProductPrice/ProductPrice';
import styles from './ProductCard.module.css';

export interface IProductCardProps {
  price: Price;
  product: ProductWithPrice;
}

export const ProductCard = (props: IProductCardProps) => {
  const { price, product } = props;

  const router = useRouter();
  const theme = useTheme();
  const t = useTranslations('Products');
  const { user } = useUser();

  const isSoonProduct = product.metadata.soon;

  const enableFreeOffer = async () => {
    if (!user) {
      return router.push('/sign-up');
    }

    const url = '/api/enable-free-offer';
    const options: RequestInit = {
      credentials: 'same-origin',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    };

    fetch(url, options)
      .then((res) => res.json())
      .then(() => {
        router.push('/');
      });
  };

  const handleCheckout = async (price: Price) => {
    if (!user) {
      return router.push('/sign-up');
    }

    const url = '/api/create-checkout-session';
    const options: RequestInit = {
      body: JSON.stringify({ price }),
      credentials: 'same-origin',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    };

    const stripe = await loadStripe(
      process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string
    );

    fetch(url, options)
      .then((res) => res.json())
      .then((data) => {
        stripe?.redirectToCheckout(data);
      });
  };

  const redirectToCustomerPortal = async (action: string) => {
    fetch('/api/create-portal-link', {
      headers: {
        'Accept-Language': router.locale as string,
        'OP-TBGPT-Action': action,
      },
      method: 'POST',
    })
      .then((res) => res.json())
      .then((data) => {
        window.location.assign(data.url);
      })
      .catch((error) => {
        if (error) return alert((error as Error).message);
      });
  };

  // Used to manage cards background and color
  const cardFilledStyle =
    price.unit_amount !== 0
      ? {
          bg: theme.colors.primary['500'],
          color: 'white',
        }
      : {
          bg: theme.colors.secondary['500'],
          color: 'black',
        };

  /*
   * Subscription link management
   */
  const isFreeUser = user?.publicMetadata.planId === 'free';
  const hasPlan = !!user?.publicMetadata.planId;
  const isCurrentSubscription = user?.publicMetadata.planId === price.id;
  let subscriptionLink;

  switch (true) {
    // Product is not available
    case isSoonProduct:
      subscriptionLink = <Text>{t('soon')}</Text>;
      break;

    // If user just created, display "Enable free offer" link for free product
    case !hasPlan && price.unit_amount === 0:
      subscriptionLink = (
        <Link onClick={() => enableFreeOffer()}>{t('enableFreeOffer')}</Link>
      );
      break;

    // If used already use free offer, display info on free product
    case isFreeUser && price.unit_amount === 0:
      subscriptionLink = <Text>{t('alreadyEnabled')}</Text>;
      break;

    // If product is the current user subscription
    case isCurrentSubscription:
      subscriptionLink = (
        <Link onClick={() => redirectToCustomerPortal('manage')}>
          {t('manage')}
        </Link>
      );
      break;

    // Else, display an update or subscribe link
    default:
      subscriptionLink = user?.publicMetadata.subscriptionId ? (
        <Link onClick={() => redirectToCustomerPortal('update')}>
          {t('changeOffer')}
        </Link>
      ) : (
        <Link onClick={() => handleCheckout(price)}>{t('subscribe')}</Link>
      );
      break;
  }

  return (
    <Card
      borderWidth="0px"
      boxShadow="md"
      key={product.id}
      opacity={isSoonProduct ? 0.3 : 1}
      p={0}
    >
      <CardHeader borderTopRadius={24} pb={10} {...cardFilledStyle}>
        <Flex direction="column" alignItems="center">
          <Heading
            as="h2"
            color={cardFilledStyle.color}
            fontSize={36}
            fontWeight={600}
            mb={8}
            size="md"
          >
            {t(`${product.name}.name`)}
          </Heading>
          <Text fontSize={18}>{t(`${product.name}.description`)}</Text>
        </Flex>
      </CardHeader>
      <CardBody className={styles.cardBody} p={0}>
        <ProductPrice color={cardFilledStyle.bg} price={price} />
        <Text>{t.rich(`${product.name}.content.line1`)}</Text>
        <Divider />
        <Text>{t.rich(`${product.name}.content.line2`)}</Text>
        <Divider />
        <Text>{t.rich(`${product.name}.content.line3`)}</Text>
        <Divider />
        <Text>{t.rich(`${product.name}.content.line4`)}</Text>
      </CardBody>
      <CardFooter className={styles.cardFooter} mt={5} {...cardFilledStyle}>
        <Center>{subscriptionLink}</Center>
      </CardFooter>
    </Card>
  );
};

export default ProductCard;
