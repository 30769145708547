import { Heading, Text } from '@chakra-ui/react';
import React from 'react';

export const TermsOfServiceContentFr = () => (
  <>
    <Text>
      Les présentes Conditions Générales d'Utilisation ont pour objet de définir
      les modalités de mise à disposition de la plateforme Toolbox GPT,
      ci-dessous dénommée «la plateforme», et les conditions d’utilisation pour
      les utilisateurs qui utilisent la version gratuite ou visitant le site
      https://toolbox-gpt.com.
      <br />
      L’utilisation de la plateforme implique l’acceptation pleine et entière
      des présentes Conditions Générales d’Utilisation.
      <br />
      La Société Online Platforms, propriétaire et développeur de la plateforme,
      se réserve le droit de refuser l’accès à la plateforme, unilatéralement et
      sans notification préalable, à tout utilisateur ne respectant pas les
      présentes Conditions Générales d’Utilisation.
      <br />
      Celles-ci sont par ailleurs susceptibles d’être modifiées ou complétées à
      tout moment ; les utilisateurs de la plateforme sont donc invités à les
      consulter régulièrement.
      <br />
    </Text>
    <Heading as="h2" size="md">
      1. Objet de Toolbox GPT
    </Heading>
    <Text>
      La plateforme Toolbox GPT a pour objet de mettre à disposition des
      utilisateurs la plateforme développée par la Société Online Platforms qui
      fonctionne avec ChatGPT.
      <br />
      Toutefois, Online Platforms ou Toolbox GPT ne pourront être tenus
      responsables des omissions, inexactitudes ou carences dans les résultats
      mis à disposition, qu’ils soient de son fait ou du fait d’un tiers
      partenaire.
      <br />
      En cas d’erreur constatée sur la plateforme, un message peut nous être
      envoyé par mail à contact@online-platforms.fr ou via notre formulaire de
      contact pour que les modifications soient appliquées.
      <br />
      Ainsi, les applications présentes sur la plateforme Toolbox GPT sont
      fournies au fur et à mesure de leur développement et, du fait de ce qui
      est indiqué ci-dessus et de l’évolution de la plateforme, sont amenées à
      évoluer. Elles sont donc données sous réserve de modifications ayant été
      apportées depuis leur mise en ligne.
      <br />
    </Text>
    <Heading as="h2" size="md">
      2. Limitations contractuelles sur les données techniques
    </Heading>
    <Text>
      Le site utilise les technologies HTML, Javascript et des services tiers
      tels que Clerk, Supabase et Stripe.
      <br />
      Online Platforms et Toolbox GPT ne pourront être tenus responsables de
      dommages matériels liés à l’utilisation de Toolbox GPT. Par ailleurs, les
      utilisateurs de la plateforme s’engagent à accéder au site avec un
      matériel ne contenant pas de virus et avec un navigateur Internet mis à
      jour. De plus, un matériel récent pourra être nécessaire pour afficher
      toutes les fonctionnalités de la plateforme.
      <br />
      Les Services sont disponibles 7 jours/7 et 24 heures/24, hormis durant les
      périodes de mise à jour ou maintenance technique qui pourront être
      nécessaires.
      <br />
    </Text>
    <Heading as="h2" size="md">
      3. Propriété intellectuelle et contrefaçon
    </Heading>
    <Text>
      Online Platforms met à disposition la plateforme développée par elle-même.
      A ce titre, elle est propriétaire de la la plateforme et des applications
      créées et en détient la propriété intellectuelle, tout comme chaque
      élément contenu sur Toolbox GPT de façon non limitative, sur le front,
      comme sur le back : graphismes, textes, ...
      <br />
      Toute reproduction, distribution, représentation, modification,
      publication, adaptation ou retransmission, même partielle, de tout élément
      contenu sur le site https://toolbox-gpt.com, quel que soit le moyen ou le
      procédé utilisé, est strictement interdite sans l’accord exprès par écrit
      de la Société Online Platforms.
      <br />
      Toute exploitation non autorisée du site https://toolbox-gpt.com ou de
      l’un des éléments qu’il contient, par quelque procédé que ce soit, sera
      considérée comme une violation du droit d’auteur et donc sanctionnée
      conformément aux dispositions des articles L.335-2 et suivants du Code de
      la Propriété Intellectuelle.
      <br />
      Les utilisateurs ne sont autorisés qu’à reproduire les pages du site
      https://toolbox-gpt.com sur leur écran ou à procéder à la copie des
      éléments autorisés par un bouton d'action et à reproduire les contenus
      qu’à titre temporaire aux seules fins d'utilisation de ladite plateforme.
      <br />
    </Text>
    <Heading as="h2" size="md">
      4. Limitation de responsabilité
    </Heading>
    <Text>
      La Société Online Platforms ne pourra être tenue responsable de dommages
      directs ou indirects survenus au cours de l’utilisation du site
      https://toolbox-gpt.com sur le matériel des utilisateurs et qui seraient
      du fait d’un matériel obsolète ou non mis à jour ainsi que d’une
      incompatibilité ou d’un bug.
    </Text>
    <Heading as="h2" size="md">
      5. Liens hypertextes et cookies
    </Heading>
    <Text>
      La Société Online Platforms et le site https://toolbox-gpt.com ne sont pas
      responsables des contenus ou de l’exactitude des éléments contenus sur les
      sites pour lesquels des liens hypertextes seraient indiqués sur le site.
      <br />
      N’étant pas propriétaire, ni responsable des sites vers lesquels les liens
      hypertextes renvoient, les utilisateurs sont invités à consulter les
      Conditions Générales d’Utilisation des sites tiers, les présentes
      conditions n’y étant pas applicables. Il en est de même pour les mentions
      légales. Les informations contenues sur les sites tiers ne peuvent être
      garanties par la Société Online Platforms. Ainsi, toute responsabilité de
      la Société Online Platforms ainsi que du site https://toolbox-gpt.com ne
      saurait être retenue.
      <br />
      Seuls des cookies fonctionnels seront installés sur l’ordinateur des
      utilisateurs lors de l’utilisation du site https://toolbox-gpt.com afin de
      pouvoir optimiser ledit site. Toutes les données recueillies resteront en
      interne à la Société Online Platforms qui s’engage à ne pas les revendre à
      des entreprises tierces.
      <br />
      Le refus d’installation d’un cookie peut empêcher l’accès à la plateforme
      Toolbox GPT.
      <br />
    </Text>
    <Heading as="h2" size="md">
      6. Litiges et juridiction
    </Heading>
    <Text>
      Tout litige relatif à l’utilisation du site https://toolbox-gpt.com est
      soumis au droit français.
    </Text>
  </>
);
