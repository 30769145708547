import { create } from 'zustand';

import { IPromptsWithTagsData, manageToastContent } from '@shared/util';

import { queryClient } from '..';
import { useToastStore } from './toast';

interface IPromptsStore {
  prompts: IPromptsWithTagsData[];

  // Actions
  createPrompt: (props: ICreatePromptProps) => void;
  getLastPrompts: (count: number) => IPromptsWithTagsData[];
  setPrompts: (prompts: IPromptsWithTagsData[]) => void;
}

interface ICreatePromptProps {
  name: string;
  prompt: string;
  tags: string[];
}

export const usePromptsStore = create<IPromptsStore>()((set, get) => ({
  createPrompt: async (props: ICreatePromptProps) => {
    const res = await fetch('/api/prompts', {
      body: JSON.stringify({ ...props }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });
    if (res.ok) {
      queryClient.invalidateQueries({ queryKey: ['prompts'] });
    }
    useToastStore
      .getState()
      .setContent(
        manageToastContent('create', 'prompt', res.ok ? 'success' : 'error'),
        'Prompts'
      );
  },
  getLastPrompts: (count) => get().prompts?.slice(0, count),
  prompts: [],
  setPrompts: (prompts: IPromptsWithTagsData[]) => set({ prompts }),
}));

export default usePromptsStore;
