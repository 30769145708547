import { Box, Heading, List, ListIcon, ListItem } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

import styles from './LastItemsList.module.css';

export interface ILastItemsListProps {
  items: {
    id: number;
    name: string;
  }[];
  title: string;
  type: string;
  textEmpty: string;
}

export const LastItemsList = (props: ILastItemsListProps) => {
  const { title, items, type, textEmpty } = props;
  const isEmpty = items.length === 0;

  return (
    <Box
      className={styles.container}
      bg="white"
      p={10}
      borderRadius={16}
      width="100%"
      h="max-content"
      marginBottom="50px"
    >
      <Heading as="h2" className={styles.title}>
        {title}
      </Heading>
      <List className={styles.list}>
        {isEmpty ? (
          <ListItem>{textEmpty}</ListItem>
        ) : (
          items.map((item) => (
            <ListItem key={item.id}>
              <ListIcon as={ChevronRightIcon} color="primary.500" />
              {item.name}
            </ListItem>
          ))
        )}
      </List>
    </Box>
  );
};

export default LastItemsList;
