import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Heading,
  Spinner,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  VStack,
  baseTheme,
} from '@chakra-ui/react';
import { useFormatter, useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { IUserSubscription } from '@shared/util';

import styles from './Subscription.module.css';

/* eslint-disable-next-line */
export interface SubscriptionProps {
  plan: string;
  subscription: IUserSubscription | undefined;
}

export const Subscription = (props: SubscriptionProps) => {
  const { plan, subscription } = props;

  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const t = useTranslations('Subscription');
  const tProducts = useTranslations('Products');
  const format = useFormatter();
  const formatDate = (date: string) =>
    format.dateTime(new Date(date), {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

  const redirectToCustomerPortal = async () => {
    setLoading(true);
    fetch('/api/create-portal-link', {
      headers: { 'Accept-Language': router.locale as string },
      method: 'POST',
    })
      .then((res) => res.json())
      .then((data) => {
        window.location.assign(data.url);
        setLoading(false);
      })
      .catch((error) => {
        if (error) return alert((error as Error).message);
      });
  };

  const subscriptionPrice =
    subscription &&
    new Intl.NumberFormat(router.locale, {
      currency: subscription?.prices?.currency,
      minimumFractionDigits: 0,
      style: 'currency',
    }).format((subscription?.prices?.unit_amount || 0) / 100);

  if (loading || (plan !== 'free' && !subscription)) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (plan === 'free') {
    return (
      <VStack mt={0} mb="30px">
        <Card
          bg="white"
          borderRadius={16}
          boxShadow="8px 8px 16px rgba(0, 0, 0, 0.2)"
          className={styles.card}
          mb={20}
          p={8}
          width="100%"
        >
          <CardHeader>
            <Heading
              marginBottom="15px"
              size="lg"
              textAlign="center"
              textTransform="uppercase"
            >
              {tProducts('toolbox-gpt.free.name')}
            </Heading>
            <Text color="primary.500" fontWeight={600} textAlign="center">
              {t('noActiveSubscription')}
            </Text>
          </CardHeader>
          <CardBody
            margin="auto"
            width={{ base: '100%', lg: '64%', md: '75%' }}
          >
            <Table size="lg">
              <TableCaption placement="top" fontWeight="600">
                {t('subscriptionDetails')}
              </TableCaption>
              <Tbody>
                <Tr>
                  <Th color="primary.500">{t('status')}</Th>
                  <Td>{t('subscriptionStatus.active')}</Td>
                </Tr>
                <Tr>
                  <Th color="primary.500">{t('price')}</Th>
                  <Td>0 €</Td>
                </Tr>
              </Tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <Button
              m="auto"
              onClick={() => router.push('/subscribe')}
              variant="primary"
            >
              {t('subscribe')}
            </Button>
          </CardFooter>
        </Card>
      </VStack>
    );
  }

  if (!subscription) return null;

  return (
    <Center>
      <VStack mt={0} mb="30px" width="100%">
        <Text color="primary.500" fontWeight={500}>
          {t('activeSubscription')}
        </Text>
        <Card
          bg="white"
          borderRadius={16}
          boxShadow="8px 8px 16px rgba(0, 0, 0, 0.2)"
          className={styles.card}
          mb={20}
          p={8}
          width="100%"
        >
          <CardHeader>
            <Heading size="lg" textAlign="center">
              {tProducts(`${subscription.prices.products.name}.name`)}
            </Heading>
          </CardHeader>
          <CardBody>
            <Table size="lg">
              <TableCaption placement="top">
                {t('subscriptionDetails')}
              </TableCaption>
              <Tbody>
                <Tr>
                  <Th color="primary.500">{t('status')}</Th>
                  <Td>{t(`subscriptionStatus.${subscription.status}`)}</Td>
                </Tr>
                <Tr>
                  <Th color="primary.500">{t('createdAt')}</Th>
                  <Td>{formatDate(subscription.created)}</Td>
                </Tr>
                <Tr>
                  <Th color="primary.500">{t('currentPeriodStart')}</Th>
                  <Td>{formatDate(subscription.current_period_start)}</Td>
                </Tr>
                <Tr>
                  <Th color="primary.500">{t('currentPeriodEnd')}</Th>
                  <Td>{formatDate(subscription.current_period_end)}</Td>
                </Tr>
                <Tr>
                  <Th color="primary.500">{t('price')}</Th>
                  <Td>
                    {subscriptionPrice} /{' '}
                    {tProducts(`priceInterval.${subscription.prices.interval}`)}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <Button
              m="auto"
              onClick={redirectToCustomerPortal}
              variant="primary"
            >
              {t('manageSubscription')}
            </Button>
          </CardFooter>
        </Card>
      </VStack>
    </Center>
  );
};

export default Subscription;
