import { useQuery } from '@tanstack/react-query';

import { useTagsStore } from '../stores';

export const useTags = () => {
  const setTags = useTagsStore((state) => state.setTags);

  // Fetch Data
  useQuery(['tags'], async () => {
    const response = await fetch('/api/tags');
    const data = await response.json();
    setTags(data);
    return data;
  });

  const store = useTagsStore();

  return {
    ...store,
  };
};

export default useTags;
