import { Heading, Text } from '@chakra-ui/react';
import React from 'react';

export const TermsOfSaleContentEn = () => (
  <>
    <Text>
      The purpose of these General Terms and Conditions of Sale is to define the
      terms of sale and provision of the Toolbox GPT platform, below referred to
      as "the platform", and detail the offers offered on the website
      https://toolbox-gpt.com.
      <br />
      Membership of the platform implies full acceptance of the these General
      Conditions of Sale.
      <br />
      The Online Platforms Company, owner and developer of the platform,
      reserves the right to refuse access to the platform, unilaterally and
      without prior notification, to any user who does not comply with the these
      General Conditions of Sale.
      <br />
      These may also be modified or supplemented at any time ; platform users
      are therefore invited to consult them regularly and to accept them when a
      change is made to them. notified. In the event that the user refuses these
      new Conditions General Sales, access to the platform would then be
      refused.
      <br />
    </Text>
    <Heading as="h2" size="md">
      1. Object
    </Heading>
    <Text>
      These conditions govern the contractual relationship between Online
      Platforms and the customer, both parties accepting them without reserve.
      <br />
      Online Platforms offers a personalized subscription service on its Toolbox
      GPT platform allowing the customer to access tools working with ChatGPT.
      <br />
    </Text>
    <Heading as="h2" size="md">
      2. Subscription
    </Heading>
    <Text>
      A free subscription is offered to test Toolbox GPT with 20 prompts and 20
      prompt optimizations offered. To continue to take advantage of the
      services beyond these trials, it is necessary to pass on a paid
      subscription.
      <br />
      Paid subscriptions can be taken out either by the month or by the year.
      They are renewable by tacit agreement from date to date and effective upon
      subscription.
      <br />
      It can be terminated at any time, the current subscription continuing
      until the next due date.
      <br />
    </Text>
    <Heading as="h2" size="md">
      3. Rates
    </Heading>
    <Text>
      The prices appearing on the site are prices including VAT in euros taking
      into account VAT applicable on the day of subscription.
      <br />
      The subscription at €1.99 incl. tax/month or €19.90 incl. tax/year gives
      access to unlimited tag and prompt functionality, plus 1000 prompt
      optimizations per month.
      <br />
    </Text>
    <Heading as="h2" size="md">
      4. Terms of payment
    </Heading>
    <Text>
      The payment of the subscription is made by credit card only via a
      third-party service, Stripe.
      <br />
    </Text>
    <Heading as="h2" size="md">
      5. Termination of subscription
    </Heading>
    <Text>
      In the event of termination, access to the customer's personal space and
      to previously performed searches will remain available, but the
      possibility to make new conversations, optimizations or prompts will be
      suspended.
      <br />
    </Text>
    <Heading as="h2" size="md">
      6. Delivery
    </Heading>
    <Text>
      The Services are provided via the Internet. Access details will be sent by
      email.
      <br />
    </Text>
    <Heading as="h2" size="md">
      7. Warranties
    </Heading>
    <Text>
      The services provided by Online Platforms benefit from the guarantee
      provided for by articles 1641 and following of the Civil Code.
      <br />
    </Text>
    <Heading as="h2" size="md">
      8. Liability
    </Heading>
    <Text>
      Online Platforms, in the process of selling online, is only required to an
      obligation of means.
      <br />
    </Text>
    <Heading as="h2" size="md">
      9. Personal data
    </Heading>
    <Text>
      Online Platforms is committed to maintaining the confidentiality of
      information provided by the buyer.
      <br />
    </Text>
    <Heading as="h2" size="md">
      10. Disputes
    </Heading>
    <Text>
      These conditions of online sale are subject to the law French.
      <br />
    </Text>
  </>
);
