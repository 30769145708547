import {
  DatabaseError,
  IUserLimits,
  IUserStats,
  IUser_data,
  LIMITS,
} from '@shared/util';
import { PostgrestSingleResponse, SupabaseClient } from '@supabase/supabase-js';

export const getUserStats = async (
  supabase: SupabaseClient,
  userUUID: string
): Promise<IUserStats> => {
  const { data, error }: PostgrestSingleResponse<IUser_data> = await supabase
    .from('user_data')
    .select('chats_count, optimizations_count, prompts_count, tags_count')
    .eq('id', userUUID)
    .single();

  if (error) {
    throw new DatabaseError({
      category: 'Supabase',
      code: 'DB0005',
      details: error,
      message: 'Error on retrieving user stats',
    });
  }

  return {
    chats: data.chats_count,
    optimizations: data.optimizations_count,
    prompts: data.prompts_count,
    tags: data.tags_count,
  } as IUserStats;
};

export const getUserSubscripedProduct = async (
  supabase: SupabaseClient,
  userUUID: string
): Promise<'FREE' | 'BASIC'> => {
  const { data, error } = await supabase
    .from('subscriptions')
    .select('prices(products(name))')
    .eq('user_id', userUUID)
    .eq('status', 'active');

  if (error) {
    if (error.code === 'PGRST116') {
      return 'FREE';
    } else {
      throw new DatabaseError({
        category: 'Supabase',
        code: 'DB0005',
        details: error,
        message: 'Error on retrieving user subscripted product',
      });
    }
  }

  if (data.length === 0) {
    return 'FREE';
  }

  return 'BASIC';
};

export const getUserLimits = async (
  supabase: SupabaseClient,
  userUUID: string
): Promise<IUserLimits> => {
  const product = await getUserSubscripedProduct(supabase, userUUID);

  return LIMITS[product];
};
