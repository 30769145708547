import { Button } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useTranslations } from 'next-intl';

import { useUser } from '@shared/data';

import styles from './PromptOptimizeRunButton.module.css';

interface IPromptOptimizeRunButtonProps {
  action: () => void;
  prompt: string;
}

export function PromptOptimizeRunButton(props: IPromptOptimizeRunButtonProps) {
  const { action, prompt } = props;
  const { isOptimizePromptEnabled, user } = useUser();
  const t = useTranslations('Prompts');

  if (!isOptimizePromptEnabled && user?.publicMetadata.planId === 'free') {
    <Button className={styles.subscribeLink} variant="primary">
      <NextLink href="/subscribe">{t('subscribe')}</NextLink>
    </Button>;
  }

  return (
    <Button
      isDisabled={prompt === '' || !isOptimizePromptEnabled}
      onClick={action}
      variant="primary"
      _disabled={{
        '&': { opacity: 0.4 },
        '&:hover': { bg: 'primary.500', opacity: '0.4' },
      }}
    >
      {t('optimize')}
    </Button>
  );
}

export default PromptOptimizeRunButton;
