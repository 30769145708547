import { UseToastOptions } from '@chakra-ui/react';
import { create } from 'zustand';

type TState = {
  content: UseToastOptions;
  namespace: string;
};

type TActions = {
  reset: () => void;
  setContent: (content: UseToastOptions, namespace: string) => void;
};

const initialState = {
  content: {},
  namespace: '',
};

export const useToastStore = create<TState & TActions>()((set) => ({
  ...initialState,
  reset: () => set(initialState),
  setContent: (content, namespace) => set({ content, namespace }),
}));
