import { Heading, Text } from '@chakra-ui/react';
import React from 'react';

export const TermsOfServiceContentEn = () => (
  <>
    <Text>
      The purpose of these General Terms and Conditions of Use is to define the
      terms and conditions for making the Toolbox GPT platform available,
      hereinafter referred to as "the platform", and the terms of use for users
      who use the free version or visit the website https://toolbox-gpt.com.
      <br />
      Use of the platform implies full acceptance of these General Terms and
      Conditions of Use.
      <br />
      The Online Platforms Company, owner and developer of the platform,
      reserves the right to refuse access to the platform, unilaterally and
      without prior notification, to any user who does not comply with these
      General Conditions of Use.
      <br />
      These are also likely to be modified or supplemented at any time; platform
      users are therefore invited to consult them regularly.
      <br />
    </Text>
    <Heading as="h2" size="md">
      1. GPT Toolbox object
    </Heading>
    <Text>
      The purpose of the Toolbox GPT platform is to provide users with the
      platform developed by the Online Platforms Company which works with
      ChatGPT.
      <br />
      However, Online Platforms or Toolbox GPT cannot be held responsible for
      any omissions, inaccuracies or deficiencies in the results made available,
      whether caused by it or by a third party partner.
      <br />
      In the event of an error noted on the platform, a message can be sent to
      us by email at contact@online-platforms.fr or via our contact form so that
      the modifications are applied.
      <br />
      Thus, the applications present on the Toolbox GPT platform are provided as
      they are developed and, due to what is indicated above and the evolution
      of the platform, are likely to evolve. They are therefore given subject to
      modifications having been made since they were put online.
      <br />
    </Text>
    <Heading as="h2" size="md">
      2. Contractual limitations on technical data
    </Heading>
    <Text>
      The site uses HTML, JavaScript and third-party services such as Clerk,
      Supabase and Stripe.
      <br />
      Online Platforms and Toolbox GPT cannot be held responsible for material
      damage related to the use of Toolbox GPT. In addition, platform users
      undertake to access the site with hardware that does not contain viruses
      and with an updated Internet browser. In addition, recent hardware may be
      required to display the full functionality of the platform.
      <br />
      The Services are available 7 days a week, 24 hours a day, except during
      periods of technical updates or maintenance that may be necessary.
      <br />
    </Text>
    <Heading as="h2" size="md">
      3. Intellectual property and counterfeiting
    </Heading>
    <Text>
      Online Platforms provides the platform developed by itself. As such, it
      owns the platform and the applications created and holds the intellectual
      property, as well as each element contained on Toolbox GPT in a
      non-limiting way, on the front, as on the back: graphics, texts, .. .
      <br />
      Any reproduction, distribution, representation, modification, publication,
      adaptation or retransmission, even partial, of any element contained on
      the site https://toolbox-gpt.com, whatever the means or process used, is
      strictly prohibited without the express agreement in writing of the Online
      Platforms Company.
      <br />
      Any unauthorized use of the https://toolbox-gpt.com site or of one of the
      elements it contains, by any process whatsoever, will be considered as a
      violation of copyright and therefore sanctioned in accordance with the
      provisions articles L.335-2 and following of the Intellectual Property
      Code.
      <br />
      Users are only authorized to reproduce the pages of the
      https://toolbox-gpt.com site on their screen or to copy the elements
      authorized by an action button and to reproduce the content only
      temporarily. for the sole purpose of using said platform.
      <br />
    </Text>
    <Heading as="h2" size="md">
      4. Limitation of Liability
    </Heading>
    <Text>
      The Online Platforms Company cannot be held responsible for direct or
      indirect damage occurring during the use of the site
      https://toolbox-gpt.com on the equipment of the users and which would be
      due to obsolete or not updated equipment. up-to-date as well as an
      incompatibility or a bug.
    </Text>
    <Heading as="h2" size="md">
      5. Hypertext links and cookies
    </Heading>
    <Text>
      The Online Platforms Company and the https://toolbox-gpt.com site are not
      responsible for the content or the accuracy of the elements contained on
      the sites for which hypertext links are indicated on the site.
      <br />
      Being neither the owner nor responsible for the sites to which the
      hypertext links refer, users are invited to consult the General Conditions
      of Use of third-party sites, these conditions not being applicable
      thereto. The same is true for legal notices. The information contained on
      third-party sites cannot be guaranteed by the Online Platforms Company.
      Thus, any responsibility of the Online Platforms Company as well as of the
      site https://toolbox-gpt.com cannot be retained.
      <br />
      Only functional cookies will be installed on users' computers when using
      the https://toolbox-gpt.com site in order to be able to optimize said
      site. All the data collected will remain internal to the Online Platforms
      Company, which undertakes not to resell it to third-party companies.
      <br />
      Refusal to install a cookie may prevent access to the Toolbox GPT
      platform.
      <br />
    </Text>
    <Heading as="h2" size="md">
      6. Disputes and Jurisdiction
    </Heading>
    <Text>
      Any dispute relating to the use of the site https://toolbox-gpt.com is
      subject to French law.
    </Text>
  </>
);
