import {
  Box,
  Center,
  Image as ChakraImage,
  Flex,
  Heading,
  Link,
  List,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useTranslations } from 'next-intl';

import { Logo } from '../Logo/Logo';

import styles from './Footer.module.css';

/* eslint-disable-next-line */
export interface FooterProps {}

export const Footer = (props: FooterProps) => {
  const t = useTranslations('Footer');

  const currentYear = new Date().getFullYear();

  return (
    <Box as="footer" bg="footer.500" className={styles.footer} w="100%">
      <Box mt={10}>
        <Center>
          <Logo boxSize="150px" />
        </Center>
      </Box>
      <VStack flexDirection="column" mt={10}>
        <Heading as="h3" size="md">
          {t('follow-us')}
        </Heading>
        <Flex>
          <Link
            href="https://www.facebook.com/OnlinePlatforms41"
            isExternal
            m={2}
          >
            <ChakraImage
              alt="Logo Facebook"
              height={30}
              width={30}
              src="/img/facebook.svg"
            />
          </Link>
          <Link
            href="https://www.linkedin.com/company/online-platforms41/"
            isExternal
            m={2}
          >
            <ChakraImage
              alt="Logo Linkedin"
              height={30}
              width={30}
              src="/img/linkedin.svg"
            />
          </Link>
        </Flex>
        <List className="footer-links" pt={5} textAlign="center">
          <ListItem fontWeight={500} mt={3}>
            <NextLink href="/faq">{t('faq')}</NextLink>
          </ListItem>
          <ListItem fontWeight={500} mt={3}>
            <NextLink href="/legal">{t('legal')}</NextLink>
          </ListItem>
          <ListItem fontWeight={500} mt={3}>
            <NextLink href="/privacy">{t('privacyPolicy')}</NextLink>
          </ListItem>
          <ListItem fontWeight={500} mt={3}>
            <NextLink href="/terms-use">{t('termsConditionsUse')}</NextLink>
          </ListItem>
          <ListItem fontWeight={500} mt={3}>
            <NextLink href="/terms-sale">{t('termsConditionsSale')}</NextLink>
          </ListItem>
        </List>
      </VStack>
      <Box mb={10} mt={12}>
        <Text fontWeight={500} mt={3} textAlign="center">
          Copyright ©{' '}
          <Link href="https://online-platforms.fr/" isExternal>
            Online Platforms
          </Link>{' '}
          {currentYear}
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
