import { useAuth, useUser } from '@clerk/nextjs';
import { useEffect, useState } from 'react';

export const useClerkAuthentication = (refreshInterval: number) => {
  const { getToken } = useAuth();
  const { isLoaded: isUserLoaded, isSignedIn, user } = useUser();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    getToken({
      template: process.env.NEXT_PUBLIC_CLERK_SUPABASE_TEMPLATE_NAME,
    }).then((token) => setToken(token));
  }, []);

  return { isSignedIn, isUserLoaded, token, user };
};
