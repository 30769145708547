import {
  Box,
  Divider,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useTranslations } from 'next-intl';

import { IUserLimits, IUserStats, TUserStatsKey } from '@shared/util';

import styles from './HomeUserStatistics.module.css';

export interface IHomeUserStatisticsProps {
  limitStatistics: IUserLimits;
  userStatistics: IUserStats;
}

export function HomeUserStatistics({
  limitStatistics,
  userStatistics,
}: IHomeUserStatisticsProps) {
  const t = useTranslations('HomeUserStatistics');

  // Build stats
  return (
    <Box className={styles.statistics}>
      <Heading as="h2">{t(`limitStatistics.title`)}</Heading>
      <Divider />
      <List>
        {Object.keys(userStatistics).map((statName) => {
          const validStatName = statName as TUserStatsKey;
          const userStat = userStatistics[validStatName];
          const limitStat = limitStatistics[validStatName];

          return (
            <ListItem key={statName} marginBottom="10px">
              <ListIcon as={ChevronRightIcon} color="primary.500" />
              <Text as="span" className={styles.label}>
                {t(`userStatistics.${validStatName}`)}
              </Text>
              <Spacer />
              <Text as="span" className={styles.value}>
                {userStat}
                <span className={styles.slash}>/</span>
                <span className={styles.limit}>
                  {limitStat === 'inf' ? (
                    <Image
                      alt="Logo infini"
                      className={styles.infinity}
                      height="20px"
                      src="/img/infinity.png"
                      width="20px"
                    />
                  ) : (
                    limitStat
                  )}
                </span>
              </Text>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

export default HomeUserStatistics;
