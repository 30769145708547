import { Heading, Link, List, ListItem, Text } from '@chakra-ui/react';
import React from 'react';

export const PrivacyContentEn = () => (
  <>
    <Heading as="h2" size="md">
      Who are we?
    </Heading>
    <Text>
      The platform <span className="bold">GPT TOOL BOX</span> is hosted at the
      address{' '}
      <Link href="https://gpt-tool-box.fr" isExternal>
        https://toolbox-gpt.com
      </Link>{' '}
      , a service developed and owned by the company{' '}
      <Link href="https://online-platforms.fr" isExternal>
        Online Platforms
      </Link>
      .
    </Text>
    <Heading as="h2" size="md">
      Data Controller
    </Heading>
    <Text>
      Online Platforms SAS processes data about you and is responsible for the
      processing of personal data collected on our site.
    </Text>
    <Heading as="h2" size="md">
      What data is collected and for what purpose?
    </Heading>
    <Text>
      Your Internet browser automatically transmits the following information
      when you connect, which we collect:
    </Text>
    <List styleType="disc">
      <ListItem>
        The operating system of your device (Windows, Mac, Android, iOS, etc.):
        analysis and display
      </ListItem>
      <ListItem>
        The Internet browser used and its language of use: customization and
        analysis
      </ListItem>
      <ListItem>Your IP address: statistical analysis</ListItem>
      <ListItem>
        The time of the visit: allows us to predict the period of least traffic
        for updates and maintenance
      </ListItem>
      <ListItem>
        Technical information about your type of device (computer, smartphone,
        tablet, etc.): analysis and display
      </ListItem>
      <ListItem>
        The website from which you were redirected to our site (if applicable):
        statistical analysis
      </ListItem>
    </List>
    <Text>
      Depending on the actions taken on the site, you may be asked to provide
      personal information about yourself:
    </Text>
    <List as="ul">
      <ListItem>Your email address: necessary to respond to you</ListItem>
      <ListItem>Your phone number: if you want us to call you back</ListItem>
      <ListItem>
        Your name and surname: to process your request and ensure that you are a
        natural person
      </ListItem>
    </List>
    <Text>
      This information will be collected to process your request and will be
      kept until you request their deletion or for a maximum period of 3 years
      after the last contact. Similarly, you can request access to the personal
      information collected about you, as well as its correction or opposition.
    </Text>
    <Text>
      Similarly, you can request access to the personal information collected
      about you, as well as its correction or opposition.
    </Text>
    <Heading as="h2" size="md">
      Who has access to your data?
    </Heading>
    <Text>
      Online Platforms SAS does not disclose or commercialize your personal data
      to external companies.
    </Text>
    <Text>
      We may use external service providers for hosting and data storage, as
      well as for the proper functioning of our services.
    </Text>
    <Text>
      We may use external service providers for hosting and data storage, as
      well as for the proper functioning of our services.
    </Text>
    <Heading as="h2" size="md">
      How long is your data stored?
    </Heading>
    <Text>
      The data will be kept for 3 years after the last contact for communication
      purposes mainly.
    </Text>
    <Heading as="h2" size="md">
      Right of access and rectification of transmitted data
    </Heading>
    <Text>
      As provided for in the GDPR, any person can request access, rectification,
      objection or deletion of their personal data.
    </Text>
    <Text>
      A simple request by email to{' '}
      <a href="mailto:contact@online-platforms.fr">
        contact@online-platforms.fr
      </a>{' '}
      is necessary to access your request, with any necessary supporting
      documents depending on the nature of the request.
    </Text>
    <Heading as="h2" size="md">
      Cookies
    </Heading>
    <Text>
      Browsing our site may result in the installation of cookies on the user's
      computer, unless the user objects. A cookie is a small file that does not
      allow the identification of the user but records information about the
      browsing of a computer on a site. The data thus obtained is intended to
      facilitate subsequent navigation on the site and also to enable various
      traffic measurement tools.
    </Text>
    <Text>
      Refusing to install a cookie may result in the inability to access certain
      services. However, the user can configure their computer to refuse the
      installation of cookies.
    </Text>
    <Heading as="h2" size="md">
      Hypertext links
    </Heading>
    <Text>
      Hypertext links to other sites are present on our site, with our
      agreement. However, we do not have the ability to check the content of the
      visited sites, and therefore assume no responsibility in this regard.
    </Text>
  </>
);
