import * as Sentry from '@sentry/nextjs';
import { ChakraProvider, Link } from '@chakra-ui/react';
import { CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { enUS, frFR } from '@clerk/localizations';
import { Analytics } from '@vercel/analytics/react';
import { AppProps } from 'next/app';
import { ClerkProvider } from '@clerk/nextjs';
import { NextIntlProvider } from 'next-intl';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useRouter } from 'next/router';

import { ErrorBoundary, ToastItemAction } from '@shared/feature';
import { mainTheme } from '@shared/ui';
import { queryClient } from '@shared/data';

import './styles.css';

function CustomApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const localizations: Record<string, any> = {
    en: enUS,
    fr: frFR,
  };

  const { locale, push } = router;

  if (!locale) return;

  // Add locale information to Sentry
  Sentry.setTag('op.locale', locale);

  return (
    <ErrorBoundary>
      <ClerkProvider
        localization={localizations[locale]}
        {...pageProps}
        navigate={(to) => push(to)}
      >
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={mainTheme}>
            <NextIntlProvider
              defaultTranslationValues={{
                br: () => <br />,
                chatGPTLink: () => (
                  <Link href="https://openai.com/product/chatgpt" isExternal>
                    ChatGPT
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                ),
                chatGPTLinkApp: () => (
                  <Link href="https://chat.openai.com" isExternal>
                    ChatGPT
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                ),
                copyIcon: () => <CopyIcon color="primary.500" mb={2} />,
                important: (chunks) => <b>{chunks}</b>,
                openAILink: () => (
                  <Link href="https://openai.com" isExternal>
                    OpenAI
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                ),
              }}
              messages={pageProps.messages}
            >
              <Component {...pageProps} />
              <Analytics />
              <ReactQueryDevtools initialIsOpen={false} />
              <ToastItemAction />
            </NextIntlProvider>
          </ChakraProvider>
        </QueryClientProvider>
      </ClerkProvider>
    </ErrorBoundary>
  );
}

export default CustomApp;
