import { Box, Text } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

import { IntlRichElement } from '@shared/util';

import styles from './InformationBox.module.css';

export interface IInformationBoxProps {
  text: IntlRichElement;
}

export function InformationBox(props: IInformationBoxProps) {
  const { text } = props;
  return (
    <Box className={styles.information} w="100%">
      <InfoOutlineIcon className={styles.informationIcon} />
      <Text>{text}</Text>
    </Box>
  );
}

export default InformationBox;
