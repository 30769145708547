import { Heading, Text } from '@chakra-ui/react';
import React from 'react';

export const TermsOfSaleContentFr = () => (
  <>
    <Text>
      Les présentes Conditions Générales de Vente ont pour objet de définir les
      modalités de vente et mise à disposition de la plateforme Toolbox GPT,
      ci-dessous dénommée «la plateforme», et détailler les offres proposées sur
      le site https://toolbox-gpt.com.
      <br />
      L’adhésion à la plateforme implique l’acceptation pleine et entière des
      présentes Conditions Générales de Vente.
      <br />
      La Société Online Platforms, propriétaire et développeur de la plateforme,
      se réserve le droit de refuser l’accès à la plateforme, unilatéralement et
      sans notification préalable, à tout utilisateur ne respectant pas les
      présentes Conditions Générales de Vente.
      <br />
      Celles-ci sont par ailleurs susceptibles d’être modifiées ou complétées à
      tout moment ; les utilisateurs de la plateforme sont donc invités à les
      consulter régulièrement et à les accepter lorsqu'un changement leur est
      notifié. Dans le cas où l'utilisateur refuserait ces nouvelles Conditions
      Générales de Vente, l'accès à la plateforme lui serait alors refusé.
      <br />
    </Text>
    <Heading as="h2" size="md">
      1. Objet
    </Heading>
    <Text>
      Les présentes conditions régissent les relations contractuelles entre
      Online Platforms et le client, les deux parties les acceptant sans
      réserve.
      <br />
      Online Platforms offre un service d'abonnement personnalisé sur sa
      plateforme Toolbox GPT permettant au client d'accéder à des outils
      fonctionnant avec ChatGPT.
      <br />
    </Text>
    <Heading as="h2" size="md">
      2. Abonnement
    </Heading>
    <Text>
      Il est proposé un abonnement gratuit permettant de tester Toolbox GPT avec
      20 prompts anisi que 20 optimisations de prompt offertes. Pour continuer à
      profiter des services au-delà de ces essais, il est nécessaire de passer
      sur un abonnement payant.
      <br />
      Les abonnements payants peuvent être souscrits soit au mois, soit à
      l'année. Ils sont renouvelables par tacite reconduction de date à date et
      effectifs dès la souscription.
      <br />
      Il peut être résilié à tout moment, l'abonnement en cours continuant
      jusqu'à la prochaine échéance.
      <br />
    </Text>
    <Heading as="h2" size="md">
      3. Tarifs
    </Heading>
    <Text>
      Les prix figurant sur le site sont des prix TTC en euro tenant compte de
      la TVA applicable au jour de la souscription.
      <br />
      L'abonnement à 1,99 € TTC/mois ou 19,90 €TTC/an permet d'avoir accès aux
      fonctionnalités de tag et de prompts en illimité, ainsi qu'à 1000
      optimisations de prompt par mois.
      <br />
    </Text>
    <Heading as="h2" size="md">
      4. Modalités de paiement
    </Heading>
    <Text>
      Le règlement de l'abonnement s'effectue par carte bancaire uniquement via
      un service tiers, Stripe.
      <br />
    </Text>
    <Heading as="h2" size="md">
      5. Résiliation de l'abonnement
    </Heading>
    <Text>
      En cas de résiliation, l'accès à l'espace personnel du client et aux
      recherches précédemment effectuées restera disponible, mais la possibilité
      de réaliser de nouvelles conversations, optimisations ou prompts sera
      suspendue.
      <br />
    </Text>
    <Heading as="h2" size="md">
      6. Livraison
    </Heading>
    <Text>
      Les services sont fournis via Internet. Les détails de l'accès seront
      envoyés par e-mail.
      <br />
    </Text>
    <Heading as="h2" size="md">
      7. Garanties
    </Heading>
    <Text>
      Les services fournis par Online Platforms bénéficient de la garantie
      légale prévue par les articles 1641 et suivants du Code Civil.
      <br />
    </Text>
    <Heading as="h2" size="md">
      8. Responsabilité
    </Heading>
    <Text>
      Online Platforms, dans le processus de vente en ligne, n'est tenue qu'à
      une obligation de moyens.
      <br />
    </Text>
    <Heading as="h2" size="md">
      9. Données personnelles
    </Heading>
    <Text>
      Online Platforms s'engage à préserver la confidentialité des informations
      fournies par l'acheteur.
      <br />
    </Text>
    <Heading as="h2" size="md">
      10. Litiges
    </Heading>
    <Text>
      Les présentes conditions de vente en ligne sont soumises à la loi
      française.
      <br />
    </Text>
  </>
);
