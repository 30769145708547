export const LIMITS = {
  BASIC: {
    chats: 0,
    optimizations: 1000,
    prompts: 'inf',
    tags: 'inf',
  },
  FREE: {
    chats: 0,
    optimizations: 20,
    prompts: 20,
    tags: 'inf',
  },
};
