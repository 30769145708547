import { Flex, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';

import { Price } from '@shared/util';

import styles from './ProductPrice.module.css';

export interface IProductPriceProps {
  color: string;
  price: Price;
}

export const ProductPrice = (props: IProductPriceProps) => {
  const { currency, interval, unit_amount: unitAmount } = props.price;
  const t = useTranslations('Products');

  return (
    <Flex align="center" justify="center">
      <Text as="sup" fontSize={24} color={props.color}>
        {currency == 'eur' ? '€' : '$'}
      </Text>
      <Text as="span" color={props.color} fontSize={48} fontWeight={400}>
        {unitAmount === undefined ? ' ' : unitAmount / 100}
      </Text>
      {unitAmount && unitAmount > 0 ? (
        <Text as="span" fontSize="x-large" color="gray.400" ml={1} pt={25}>
          /{t(`priceInterval.${interval}`)}
        </Text>
      ) : null}
    </Flex>
  );
};

export default ProductPrice;
