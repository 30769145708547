import { useTranslations } from 'next-intl';

import { InformationBox } from '@shared/ui';
import { useUser } from '@shared/data';

export const PromptOptimizeUsage = () => {
  const { isOptimizePromptEnabled, remainingOptimizations, user } = useUser();
  const t = useTranslations('Prompts');

  if (isOptimizePromptEnabled)
    return (
      <InformationBox
        text={t.rich('optimizationsUsageRemaining', {
          remainingOptimizations,
        })}
      />
    );

  return (
    <InformationBox
      text={
        user?.publicMetadata.planId === 'free'
          ? t.rich('optimizationsLimitFreeReached')
          : t.rich('optimizationsLimitReached')
      }
    />
  );
};

export default PromptOptimizeUsage;
