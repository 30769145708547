import { create } from 'zustand';

import { ITags, manageToastContent } from '@shared/util';

import { queryClient } from '..';
import { useToastStore } from './toast';

interface ITagsStore {
  selectedTags: string[];
  tags: ITags[];

  // Actions
  createTag: (name: string) => void;
  deleteTag: (tagId: number) => void;
  editTag: (tagId: number, tagName: string) => void;
  getLastTags: (count: number) => ITags[];
  selectTag: (tag: string) => void;
  setTags: (tags: ITags[]) => void;
}

export const useTagsStore = create<ITagsStore>()((set, get) => ({
  createTag: async (tagName: string) => {
    const res = await fetch('/api/tags', {
      body: JSON.stringify({ tagName }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });
    if (res.ok) {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
    }
    useToastStore
      .getState()
      .setContent(
        manageToastContent('create', 'tag', res.ok ? 'success' : 'error'),
        'Tags'
      );
  },
  deleteTag: async (tagId: number) => {
    const res = await fetch(`/api/tags/${tagId}`, { method: 'DELETE' });
    if (res.ok) {
      queryClient.invalidateQueries({ queryKey: ['prompts'] });
      queryClient.invalidateQueries({ queryKey: ['tags'] });
    }
    useToastStore
      .getState()
      .setContent(
        manageToastContent('delete', 'tag', res.ok ? 'success' : 'error'),
        'Tags'
      );
  },
  editTag: async (tagId: number, tagName: string) => {
    const res = await fetch(`/api/tags/${tagId}`, {
      body: JSON.stringify({ tagName }),
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
    });
    if (res.ok) {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
    }
    useToastStore
      .getState()
      .setContent(
        manageToastContent('edit', 'tag', res.ok ? 'success' : 'error'),
        'Tags'
      );
  },
  getLastTags: (count) => get().tags?.slice(0, count),
  selectTag: (tagName: string) => {
    const selectedTags = get().selectedTags;
    set(() => {
      if (selectedTags.includes(tagName)) {
        return {
          selectedTags: selectedTags.filter((item) => item !== tagName),
        };
      }
      return { selectedTags: [...selectedTags, tagName] };
    });
  },
  selectedTags: [],
  setTags: (tags: ITags[]) => set({ tags }),
  tags: [],
}));

export default useTagsStore;
