import { Box, Button, Heading, Link, Text, VStack } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';

import { IntlRichElement } from '@shared/util';

import styles from './ErrorContainer.module.css';

export interface IErrorContainerProps {
  content: IntlRichElement;
  image?: string;
  title: IntlRichElement;
}

export function ErrorContainer(props: IErrorContainerProps) {
  const { content, title } = props;

  const t = useTranslations('ErrorContainer');

  return (
    <Box
      as="section"
      bg="white"
      borderRadius={16}
      boxShadow="8px 8px 16px rgba(0, 0, 0, 0.2)"
      className="SectionError"
      maxW={600}
      mb="50px !important"
      mx="20px !important"
      p={8}
    >
      <VStack>
        <Heading
          as="h2"
          fontSize={24}
          textAlign="center"
          textTransform="uppercase"
          mb={10}
        >
          {title}
        </Heading>
        <Text textAlign="center">{content}</Text>
        <Box display="flex" flexDirection={{ base: 'column', lg: 'row' }}>
          <Button
            className={styles.button}
            variant="primary"
            m={10}
            mb={{ base: '10px', lg: '30px' }}
            mt={{ base: '10px', lg: '30px' }}
          >
            <Link href="/">{t('backToHome')}</Link>
          </Button>
          <Button
            className={styles.button}
            variant="primary"
            m={10}
            mt={{ base: '10px', lg: '30px' }}
          >
            <Link href="/contact">{t('contactUs')}</Link>
          </Button>
        </Box>
      </VStack>
    </Box>
  );
}

export default ErrorContainer;
