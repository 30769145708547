import { useUser as useClerkUser } from '@clerk/nextjs';
import { useQuery } from '@tanstack/react-query';

import { useUserStore } from '../stores';

export const useUser = () => {
  const setLimits = useUserStore((state) => state.setLimits);
  const setStats = useUserStore((state) => state.setStats);

  // Fetch Data
  useQuery(['limits'], async () => {
    const response = await fetch('/api/user/limits');
    const data = await response.json();
    setLimits(data);
    return data;
  });
  useQuery(['stats'], async () => {
    const response = await fetch('/api/user/stats');
    const data = await response.json();
    setStats(data);
    return data;
  });

  const { isLoaded, isSignedIn, user } = useClerkUser();

  const store = useUserStore();

  return {
    isLoaded,
    isSignedIn,
    user,
    ...store,
  };
};

export default useUser;
