import { Tooltip, useToast } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useTranslations } from 'next-intl';

import styles from './CopyToClipboardIcon.module.css';

export interface ICopyToClipboardIconProps {
  data: string;
  tooltipLabel?: string;
}

export function CopyToClipboardIcon(props: ICopyToClipboardIconProps) {
  const { data, tooltipLabel } = props;

  const t = useTranslations('CopyToClipboardIcon');
  const toast = useToast();

  return (
    <Tooltip
      className={styles.tooltip}
      label={tooltipLabel || t('copyContent')}
      placement="auto"
    >
      <CopyIcon
        className={styles.icon}
        onClick={async () => {
          await navigator.clipboard.writeText(data);
          toast({
            colorScheme: 'primary',
            description: '',
            duration: 3000,
            isClosable: true,
            status: 'info',
            title: t('contentCopied'),
          });
        }}
      />
    </Tooltip>
  );
}

export default CopyToClipboardIcon;
