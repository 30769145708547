import { Center, Container, Grid, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';

import type { BillingInterval, ProductWithPrice } from '@shared/util';
import { BillingIntervalSelector, ProductCard } from '@shared/ui';

/* eslint-disable-next-line */
export interface IProductsCardsProps {}

export function ProductsCards(props: IProductsCardsProps) {
  const t = useTranslations('Subscribe');

  const [products, setProducts] = useState<ProductWithPrice[]>([]);
  const [billingInterval, setBillingInterval] =
    useState<BillingInterval>('month');

  useEffect(() => {
    fetch('/api/products')
      .then((res) => {
        return res.json();
      })
      .then((data) => setProducts(data))
      .catch((error) => console.error(error));
  }, []);

  const isLoading = Boolean(!products.length);
  return (
    <Container maxW="container.lg" py={8}>
      {isLoading ? (
        <Center>
          <Spinner color="primary.500" />
        </Center>
      ) : (
        <>
          <BillingIntervalSelector
            billingInterval={billingInterval}
            setBillingInterval={setBillingInterval}
          />
          <Grid
            templateColumns="repeat(auto-fit, minmax(250px, 1fr))"
            gap={12}
            bg="white"
            borderRadius={16}
            boxShadow="8px 8px 16px rgba(0, 0, 0, 0.2)"
            p={10}
          >
            {products.map((product) => {
              const price = product?.prices?.find(
                (price) => price.interval === billingInterval
              );
              if (!price) return null;
              return (
                <ProductCard key={product.id} price={price} product={product} />
              );
            })}
          </Grid>
        </>
      )}
    </Container>
  );
}

export default ProductsCards;
