import { Box, Heading, List, ListIcon, ListItem, Tag } from '@chakra-ui/react';

import styles from './LastTagsList.module.css';

export interface ILastTagsListProps {
  items: {
    id: number;
    name: string;
  }[];
  title: string;
  type: string;
  textEmpty: string;
}

export const LastTagsList = (props: ILastTagsListProps) => {
  const { title, items, type, textEmpty } = props;
  const isEmpty = items.length === 0;

  return (
    <Box
      className={styles.container}
      bg="white"
      p={10}
      borderRadius={16}
      width="100%"
      h="max-content"
      marginBottom="2em"
    >
      <Heading as="h2" className={styles.title}>
        {title}
      </Heading>
      <List className={styles.list} display="flex" flexWrap="wrap">
        {isEmpty ? (
          <ListItem>{textEmpty}</ListItem>
        ) : (
          items.map((item) => (
            <ListItem key={item.id}>
              <Tag
                bg="primary.500"
                boxShadow="6px 6px 12px #cbced1, -6px -6px 12px #ffffff"
                color="white"
                m={2}
                size="lg"
                variant="subtle"
              >
                {item.name}
              </Tag>
            </ListItem>
          ))
        )}
      </List>
    </Box>
  );
};

export default LastTagsList;
