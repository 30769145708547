import { useEffect, useState } from 'react';
import { Progress } from '@chakra-ui/react';

export interface IFixedDurationProgressBarProps {
  colorScheme?: string;
  duration?: number;
  size?: string;
  width?: string;
}

export function FixedDurationProgressBar(
  props: IFixedDurationProgressBarProps
) {
  const {
    colorScheme = 'primary',
    duration = 30,
    size = 'xs',
    width = '100%',
  } = props;

  const [progressValue, setProgressValue] = useState(0);
  const startTime = Date.now();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const progress = (elapsedTime / (duration * 1000)) * 100;
      setProgressValue(progress >= 100 ? 100 : progress);
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Progress
      colorScheme={colorScheme}
      hasStripe
      isAnimated
      max={100}
      min={0}
      size={size}
      value={progressValue}
      width={width}
    />
  );
}

export default FixedDurationProgressBar;
