import * as Sentry from '@sentry/nextjs';
import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';

import {
  FixedDurationProgressBar,
  PromptOptimizeModalContent,
} from '@shared/ui';
import { isStatusCode2xx } from '@shared/util';
import { queryClient } from '@shared/data';

import styles from './PromptOptimizeModal.module.css';

export interface IPromptOptimizeModalProps {
  isOpen: boolean;
  onClose: () => void;
  prompt: string;
  setPrompt: (prompt: string) => void;
}

export function PromptOptimizeModal(props: IPromptOptimizeModalProps) {
  const { isOpen, onClose, prompt, setPrompt } = props;

  const [optimizedPrompt, setOptimizedPrompt] = useState<string>('');
  const [tips, setTips] = useState<string>('');
  const t = useTranslations('PromptOptimizeModal');

  useEffect(() => {
    isOpen && handleOptimize();
  }, [isOpen]);

  const clearOptimizedContent = () => {
    setOptimizedPrompt('');
    setTips('');
  };

  const fetchOptimizePrompt = (options = {}) =>
    fetch('/api/optimize-prompt', options)
      .then((res) => {
        if (isStatusCode2xx(res.status)) return res.json();
        Sentry.captureMessage('Error when running optimization', 'error');
      })
      .then((data) => {
        data && setTips(data[0]);
        data && setOptimizedPrompt(data[1]);
        queryClient.invalidateQueries({ queryKey: ['stats'] });
      })
      .catch((error) => {
        const scope = new Sentry.Scope();
        scope.setTag('op.functionName', 'fetchOptimizePrompt');
        Sentry.captureException(error, scope);
      });

  const replaceByOptimizedPrompt = () => {
    setPrompt(optimizedPrompt);
    clearOptimizedContent();
    onClose();
  };

  const handleOptimize = async () => {
    const options = {
      body: JSON.stringify({ prompt }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    };
    clearOptimizedContent();
    fetchOptimizePrompt(options);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent className={styles.modalContent}>
        <ModalHeader className={styles.title}>
          {t('optimizationProcess')}
        </ModalHeader>
        <ModalCloseButton color="primary.500" />
        <ModalBody>
          <PromptOptimizeModalContent
            content={prompt}
            title={t('originalPrompt')}
          />
          {optimizedPrompt ? (
            <>
              <PromptOptimizeModalContent
                content={tips}
                title={t('optimizedResultTips')}
              />
              <PromptOptimizeModalContent
                content={optimizedPrompt}
                title={t('optimizedResultProposal')}
              />
            </>
          ) : (
            <>
              <Text>{t('optimizationInProgress')}</Text>
              <Center>
                <FixedDurationProgressBar />
              </Center>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={!optimizedPrompt}
            mr={5}
            onClick={handleOptimize}
            variant="primary"
          >
            {t('relaunchAnOptimization')}
          </Button>
          <Spacer />
          <Button
            isDisabled={!optimizedPrompt}
            mr={5}
            onClick={onClose}
            variant="primary"
          >
            {t('keepOriginalPrompt')}
          </Button>
          <Button
            isDisabled={!optimizedPrompt}
            mr={5}
            onClick={replaceByOptimizedPrompt}
            variant="primary"
          >
            {t('replaceByOptimizedPrompt')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default PromptOptimizeModal;
