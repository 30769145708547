import * as Sentry from '@sentry/nextjs';
import { Component, ErrorInfo, ReactNode } from 'react';

import { AuthenticationError } from '@shared/util';

interface IErrorBoundaryProps {
  children: ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  public state: IErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): IErrorBoundaryState {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.captureException(
      new AuthenticationError({
        category: 'Clerk',
        code: 'AUTH1001',
        details: { error, errorInfo },
        message: 'Network issue',
      })
    );
    window.location.href = '/';
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
