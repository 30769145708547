import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useTheme,
} from '@chakra-ui/react';
import { EmailIcon, HamburgerIcon } from '@chakra-ui/icons';
import { SignedIn, SignedOut, UserButton } from '@clerk/nextjs';
import NextLink from 'next/link';
import { useTranslations } from 'next-intl';

import { LanguageSelector } from '../../';

import styles from './AppBar.module.css';

/* eslint-disable-next-line */
interface AppBarProps {}

export const AppBar = (props: AppBarProps) => {
  const t = useTranslations('AppBar');
  const theme = useTheme();

  const menuItemStyle = {
    sx: {
      _hover: {
        backgroundColor: 'white',
        color: theme.colors.primary['500'],
        textDecoration: 'none',
      },
      color: '#4a4a4a',
      fontSize: 18,
      fontWeight: 500,
    },
  };
  const menuItemLinkStyle = {
    sx: {
      _hover: {
        textDecoration: 'none',
      },
    },
  };

  return (
    <Flex
      align="start"
      as="nav"
      justify="space-between"
      padding="1.5rem"
      wrap="wrap"
      width="100%"
      marginBottom={{ base: '30px', lg: 0 }}
    >
      {/* Will be used for burger menu */}
      <Flex align="left" width="150px">
        <VStack alignItems="start">
          <HStack>
            <Text
              color="primary.500"
              display={{ base: 'none', lg: 'block' }}
              fontWeight={600}
            >
              {t('menu')}
            </Text>
            <Image
              display={{ base: 'none', lg: 'block' }}
              h={4}
              ml={3}
              src="/img/menu-cursor.svg"
            />
          </HStack>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label={t('menu')}
              border={0}
              color={theme.colors.primary['500']}
              icon={<HamburgerIcon />}
              fontSize={45}
              variant="outline"
            />
            <MenuList
              borderRadius={20}
              boxShadow="10px 10px 20px #d1d9e6, -10px 0px 20px #ffffff;"
              className={styles.menuList}
            >
              <MenuItem {...menuItemStyle}>
                <NextLink href="/" passHref>
                  <Link {...menuItemLinkStyle}>{t('home')}</Link>
                </NextLink>
              </MenuItem>
              <SignedIn>
                <MenuItem {...menuItemStyle}>
                  <NextLink href="/profile" passHref>
                    <Link {...menuItemLinkStyle}>{t('my-account')}</Link>
                  </NextLink>
                </MenuItem>
                <MenuItem {...menuItemStyle}>
                  <NextLink href="/tags" passHref>
                    <Link {...menuItemLinkStyle}>{t('tags')}</Link>
                  </NextLink>
                </MenuItem>
                <MenuItem {...menuItemStyle}>
                  <NextLink href="/prompts" passHref>
                    <Link {...menuItemLinkStyle}>{t('prompts')}</Link>
                  </NextLink>
                </MenuItem>
              </SignedIn>
              <MenuItem {...menuItemStyle}>
                <NextLink href="/subscribe" passHref>
                  <Link {...menuItemLinkStyle}>{t('subscribe')}</Link>
                </NextLink>
              </MenuItem>
              <MenuItem {...menuItemStyle} borderBottomRadius={20}>
                <NextLink href="/contact" passHref>
                  <Link {...menuItemLinkStyle}>{t('contact')}</Link>
                </NextLink>
              </MenuItem>
              <MenuItem
                {...menuItemStyle}
                borderBottomRadius={20}
                display={{ base: 'block', lg: 'none' }}
              >
                <NextLink href="/faq" passHref>
                  <Link {...menuItemLinkStyle}>{t('faq')}</Link>
                </NextLink>
              </MenuItem>
              <SignedOut>
                <MenuItem {...menuItemStyle} borderBottomRadius={20}>
                  <NextLink href="/sign-in" passHref>
                    <Link {...menuItemLinkStyle}>{t('sign-in')}</Link>
                  </NextLink>
                </MenuItem>
              </SignedOut>
            </MenuList>
          </Menu>
        </VStack>
      </Flex>

      <Flex
        align="right"
        className={styles.rightBlock}
        justify="center"
        alignItems="center"
      >
        <Box display={{ base: 'none', lg: 'block' }}>
          <SignedOut>
            <NextLink href="sign-in" passHref>
              <Link color="primary.500" fontSize={20} fontWeight={500} pr={5}>
                {t('sign-in')}
              </Link>
            </NextLink>
          </SignedOut>
        </Box>
        <NextLink href="faq">
          <Avatar
            className={styles.questionIcon}
            display={{ base: 'none', lg: 'block' }}
            name="?"
          />
        </NextLink>
        <SignedOut>
          <NextLink href="contact">
            <Avatar
              className={styles.emailIcon}
              display={{ base: 'none', lg: 'block' }}
              icon={<EmailIcon fontSize="2xl" />}
            />
          </NextLink>
        </SignedOut>
        <UserButton
          afterSignOutUrl="/"
          appearance={{
            elements: {
              userButtonAvatarBox: {
                height: 48,
                width: 48,
              },
              userButtonBox: {
                marginRight: 10,
              },
            },
            variables: {
              colorPrimary: theme.colors.primary['500'],
            },
          }}
          userProfileMode="navigation"
          userProfileUrl="/profile"
        />
        <LanguageSelector />
      </Flex>
    </Flex>
  );
};

export default AppBar;
