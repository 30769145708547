import { useQuery } from '@tanstack/react-query';

import { usePromptsStore } from '../stores';

export const usePrompts = () => {
  const setPrompts = usePromptsStore((state) => state.setPrompts);

  // Fetch Data
  useQuery(['prompts'], async () => {
    const response = await fetch('/api/prompts');
    const data = await response.json();
    setPrompts(data);
    return data;
  });

  const store = usePromptsStore();

  return {
    ...store,
  };
};

export default usePrompts;
