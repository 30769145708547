import { Webhook, WebhookRequiredHeaders } from 'svix';
import { NextApiRequest } from 'next';
import { buffer } from 'micro';

import type { WebhookEvent } from '@clerk/clerk-sdk-node';

import { AuthenticationError } from './errors';

export const checkClerkWebhook = async (
  req: NextApiRequest,
  endpointSecret: string | undefined
): Promise<WebhookEvent> => {
  const svixExpectedHeaders = ['svix-id', 'svix-signature', 'svix-timestamp'];

  const areExpectedHeaders = svixExpectedHeaders.every((header) =>
    Object.keys(req.headers).includes(header)
  );

  if (!areExpectedHeaders) {
    throw new AuthenticationError({
      category: 'Clerk',
      code: 'AUTH0001',
      message: 'Expected headers absent',
    });
  }

  if (!endpointSecret) {
    throw new AuthenticationError({
      category: 'Clerk',
      code: 'AUTH0002',
      message: 'Private webhook secret is not defined',
    });
  }

  const payload = (await buffer(req)).toString();
  const headers: WebhookRequiredHeaders = {
    'svix-id': req.headers['svix-id'] as string,
    'svix-signature': req.headers['svix-signature'] as string,
    'svix-timestamp': req.headers['svix-timestamp'] as string,
  };
  const webhook = new Webhook(endpointSecret);
  let msg: WebhookEvent;

  try {
    msg = webhook.verify(payload, headers) as WebhookEvent;
    return msg;
  } catch (error) {
    throw new AuthenticationError({
      category: 'Clerk',
      code: 'AUTH0003',
      details: error,
      message: 'Invalid webhook signature',
    });
  }
};
