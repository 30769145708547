import { SupabaseClient, createClient } from '@supabase/supabase-js';
import { DatabaseError } from './errors';

const supabaseOptions = {
  auth: {
    autoRefreshToken: false,
    detectSessionInUrl: false,
    persistSession: false,
  },
};

export const getSupabasePrivateClient = (): SupabaseClient => {
  const privateSupabaseKey = process.env.SUPABASE_KEY_SR;
  const publicSupabaseURL = process.env.NEXT_PUBLIC_SUPABASE_URL;

  if (!privateSupabaseKey || !publicSupabaseURL) {
    throw new DatabaseError({
      category: 'Supabase',
      code: 'DB0001',
      message: 'Invalid private Supabase key or URL',
    });
  }

  return createClient(publicSupabaseURL, privateSupabaseKey, supabaseOptions);
};

export const getSupabasePublicClient = (
  supabaseAccessToken: string | null
): SupabaseClient => {
  const publicSupabaseKey = process.env.NEXT_PUBLIC_SUPABASE_KEY;
  const publicSupabaseURL = process.env.NEXT_PUBLIC_SUPABASE_URL;

  if (!publicSupabaseKey || !publicSupabaseURL) {
    throw new DatabaseError({
      category: 'Supabase',
      code: 'DB0001',
      message: 'Invalid public Supabase key or URL',
    });
  }

  if (!supabaseAccessToken) {
    throw new DatabaseError({
      category: 'Supabase',
      code: 'DB0001',
      message: 'Invalid access token',
    });
  }

  return createClient(publicSupabaseURL, publicSupabaseKey, {
    global: { headers: { Authorization: `Bearer ${supabaseAccessToken}` } },
    ...supabaseOptions,
  });
};

export const createEntity = async (
  client: SupabaseClient,
  tableName: string,
  entity: any
) => {
  const { data, error } = await client
    .from(tableName)
    .insert(entity)
    .select()
    .single();
  if (error) {
    throw new DatabaseError({
      category: 'Supabase',
      code: 'DB0002',
      details: error,
      message: `Error during ${tableName} insert`,
    });
  }
  return data;
};

export const updateEntity = async (
  client: SupabaseClient,
  tableName: string,
  entity: any,
  id: string,
  idColumn = 'id'
) => {
  if (!id) {
    throw new DatabaseError({
      category: 'Supabase',
      code: 'DB0003',
      details: { [idColumn]: id },
      message: `Invalid ID, update on ${tableName} forbidden`,
    });
  }

  const { data, error } = await client
    .from(tableName)
    .update(entity)
    .eq(idColumn, id)
    .select();
  if (error) {
    throw new DatabaseError({
      category: 'Supabase',
      code: 'DB0003',
      details: error,
      message: `Error during ${tableName} update`,
    });
  }
  return data;
};

export const upsertEntity = async (
  client: SupabaseClient,
  tableName: string,
  entity: any
) => {
  if (!entity.id) {
    throw new DatabaseError({
      category: 'Supabase',
      code: 'DB0003',
      details: { id: entity.id },
      message: `Invalid ID, upsert on ${tableName} forbidden`,
    });
  }

  const { data, error } = await client.from(tableName).upsert(entity).select();
  if (error) {
    throw new DatabaseError({
      category: 'Supabase',
      code: 'DB0003',
      details: error,
      message: `Error during ${tableName} upsert`,
    });
  }
  return data;
};

export const deleteEntity = async (
  client: SupabaseClient,
  tableName: string,
  id: string,
  idColumn = 'id'
) => {
  if (!id) {
    throw new DatabaseError({
      category: 'Supabase',
      code: 'DB0004',
      details: { [idColumn]: id },
      message: `Invalid ID, delete on ${tableName} forbidden`,
    });
  }

  const { data, error } = await client
    .from(tableName)
    .delete()
    .eq(idColumn, id);
  if (error) {
    throw new DatabaseError({
      category: 'Supabase',
      code: 'DB0004',
      details: error,
      message: `Error during ${tableName} delete`,
    });
  }
  return data;
};
