import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';

import { useToastStore } from '@shared/data';

export const ToastItemAction = () => {
  const { content, namespace, reset } = useToastStore();
  const t = useTranslations(namespace);
  const toast = useToast();

  useEffect(() => {
    if (content?.id && !toast.isActive(content.id)) {
      toast({ ...content, description: t(content.description) });
      reset();
    }
  }, [content]);

  return <></>;
};

export default ToastItemAction;
