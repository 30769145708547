import {
  Box,
  HStack,
  Link,
  Spacer,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useFormatter, useNow, useTranslations } from 'next-intl';

import { usePrompts, useTags } from '@shared/data';
import { CopyToClipboardIcon } from '@shared/ui';

import styles from './PromptsList.module.css';

export interface IPromptsListProps {
  applyFilters?: boolean;
  textEmpty: string;
}

export function PromptsList(props: IPromptsListProps) {
  const { applyFilters = false, textEmpty } = props;

  const t = useTranslations('PromptsList');
  const format = useFormatter();
  const now = useNow({ updateInterval: 1000 * 10 });

  const { selectedTags } = useTags();
  const { prompts } = usePrompts();

  const filteredPrompts =
    !applyFilters || selectedTags.length === 0
      ? prompts
      : prompts.filter((prompt) =>
          prompt.tags.some((tag) => selectedTags.includes(tag.name))
        );

  const isEmpty = !prompts || prompts.length === 0;

  const isWideVersion = useBreakpointValue(
    {
      base: false,
      lg: true,
    },
    {
      fallback: 'lg',
    }
  );

  return isWideVersion ? renderWideVersion() : renderNarrowVersion();

  function renderWideVersion() {
    return (
      <Table className={styles.promptsList}>
        <Thead>
          <Tr bg="primary.500" color="white">
            <Th borderTopLeftRadius={10}>{t('promptName')}</Th>
            <Th>{t('promptUpdateDate')}</Th>
            <Th borderTopRightRadius={10}>{t('tags')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isEmpty ? (
            <Tr>
              <Td colSpan={3} p="25px">
                {textEmpty}
              </Td>
            </Tr>
          ) : (
            filteredPrompts?.map((prompt) => (
              <Tr key={prompt.id}>
                <Td className={styles.promptName}>
                  <Link className={styles.link} href={`/prompt/${prompt.id}`}>
                    {prompt.name}
                  </Link>
                  <Spacer />
                  <CopyToClipboardIcon
                    data={prompt.content[prompt.content?.length - 1]['data']}
                    tooltipLabel={t('copyPromptContent')}
                  />
                </Td>
                <Td>{format.relativeTime(new Date(prompt.updated_at), now)}</Td>
                <Td>
                  {prompt.tags &&
                    prompt.tags.map((tag) => (
                      <Tag className={styles.tag} key={tag.id}>
                        {tag.name}
                      </Tag>
                    ))}
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    );
  }

  function renderNarrowVersion() {
    return (
      <>
        {filteredPrompts.map((prompt) => (
          <Box
            key={prompt.id}
            p={4}
            mb={6}
            mt="30px"
            borderRadius="16px"
            bg="white"
            boxShadow="12px 12px 24px #cbced1, -12px -12px 24px #ffffff;"
            position="relative"
            width={{ base: '100%', lg: '65%' }}
          >
            <HStack
              alignItems="flex-start"
              display="flex"
              flexDirection="column"
              mb={2}
            >
              <Text color="primary.500" fontSize="18px" fontWeight="bold">
                {t('promptName')} :
              </Text>
              <Text ml="0 !important">
                <Link className={styles.link} href={`/prompt/${prompt.id}`}>
                  {prompt.name}
                </Link>
              </Text>
            </HStack>
            <HStack
              alignItems="flex-start"
              display="flex"
              flexDirection="column"
              mb={2}
            >
              <Text color="primary.500" fontSize="18px" fontWeight="bold">
                {t('promptUpdateDate')} :
              </Text>
              <Text ml="0 !important">
                {format.relativeTime(new Date(prompt.updated_at), now)}
              </Text>
            </HStack>
            <HStack
              alignItems="flex-start"
              display="flex"
              flexDirection="column"
              mb={2}
            >
              <Text color="primary.500" fontSize="18px" fontWeight="bold">
                {t('tags')} :
              </Text>
              <Box>
                {prompt.tags &&
                  prompt.tags.map((tag) => (
                    <Tag
                      className={styles.tag}
                      key={tag.id}
                      ml="0 !important"
                      mt="10px !important"
                    >
                      {tag.name}
                    </Tag>
                  ))}
              </Box>
            </HStack>
            <Box position="absolute" bottom="15px" right="15px">
              <CopyToClipboardIcon
                data={prompt.content[prompt.content?.length - 1]['data']}
                tooltipLabel={t('copyPromptContent')}
              />
            </Box>
          </Box>
        ))}
        {isEmpty && <Box m="25px">{textEmpty}</Box>}
      </>
    );
  }
}

export default PromptsList;
