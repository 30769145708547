import { AlertStatus, UseToastOptions } from '@chakra-ui/react';

export const capitalize = (term: string) =>
  term.charAt(0).toUpperCase() + term.slice(1);

export const handleEnterKeyPress = (
  event: any,
  handleValidation: () => void
) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    handleValidation();
  }
};

export const isStatusCode2xx = (status: number) =>
  status >= 200 && status < 300;

export const manageToastContent = (
  action: string,
  item: string,
  status: AlertStatus,
  id?: number | string
): UseToastOptions => ({
  description: `${action}${capitalize(item)}.${status}`,
  duration: 3000,
  id: `${action}-${item}${id ? id : ''}`,
  isClosable: true,
  status,
});

export const toDateTime = (secs: number) => {
  const t = new Date('1970-01-01T00:30:00Z');
  t.setSeconds(secs);
  return t;
};

export const unquote = (text: string) => {
  const isQuoted =
    (text.startsWith("'") && text.endsWith("'")) ||
    (text.startsWith('"') && text.endsWith('"'));

  if (isQuoted && text.length >= 2) {
    return text.slice(1, -1);
  }

  return text;
};
