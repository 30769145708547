import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { Montserrat } from '@next/font/google';
import { extendTheme } from '@chakra-ui/react';

const montserrat = Montserrat({ subsets: ['latin'] });

const buttonVariants: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '4px 4px 0 0',
    padding: '10px 20px',
  },
  variants: {
    primary: {
      bg: 'primary.500',
      borderRadius: '10px',
      boxShadow: '5px 5px 10px #d9d9d9, -5px -5px 10px #ffffff',
      color: 'white',
    },
    secondary: {
      bg: 'white',
      borderRadius: '8px 8px 0 0',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
  },
};

const checkboxVariants: ComponentStyleConfig = {
  variants: {
    circular: {
      container: {
        borderRadius: '5px',
        padding: 1,
      },
      control: {
        border: '1px solid',
        borderColor: 'primary.500',
        rounded: 'full',
      },
    },
  },
};

const Divider: ComponentStyleConfig = {
  baseStyle: {
    borderColor: 'primary.500',
  },
};

const Heading: ComponentStyleConfig = {
  baseStyle: {
    color: 'primary.500',
  },
};

const Select: ComponentStyleConfig = {
  baseStyle: {
    field: {
      bg: 'primary.500',
      borderColor: 'primary.500',
      color: 'white',
      fontSize: 20,
      option: {
        bg: 'primary.500',
        color: 'white',
        fontSize: 20,
      },
    },
    icon: {
      color: 'white',
    },
  },
  parts: ['field', 'icon'],
};

export const mainTheme = extendTheme({
  colors: {
    background: {
      500: '#F2F2F2',
    },
    containerBackground: {
      500: '#F0F0F0',
    },
    footer: {
      500: '#FFD39B',
    },
    primary: {
      500: '#FF385C',
      600: '#FF385C',
    },
    secondary: {
      500: '#FF944B',
      600: '#222',
    },
  },
  components: {
    Button: buttonVariants,
    Checkbox: checkboxVariants,
    Divider,
    Heading,
    Select,
  },
  fonts: {
    body: montserrat.style.fontFamily,
    heading: montserrat.style.fontFamily,
  },
  initialColorMode: 'light',
  styles: {
    global: {
      a: {
        color: 'primary.500',
      },
      body: {
        bg: 'background.500',
      },
      h1: {
        fontSize: 28,
        fontWeight: 500,
        textTransform: 'uppercase',
      },
      h2: {
        fontSize: 28,
        fontWeight: 500,
        marginTop: '40px',
      },
    },
  },
  useSystemColorMode: true,
});
