import { ChangeEvent } from 'react';
import { Select } from '@chakra-ui/react';
import { useRouter } from 'next/router';

/* eslint-disable-next-line */
export interface LanguageSelectorProps {}

export function LanguageSelector(props: LanguageSelectorProps) {
  const router = useRouter();

  const handleLocaleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newLocale = event.target.value;
    router.push(router.pathname, router.asPath, { locale: newLocale });
  };

  return (
    <Select
      bg="primary.500"
      display="flex"
      focusBorderColor="primary.500"
      fontSize={20}
      icon={<></>}
      justifyContent="flex-end"
      maxW={150}
      onChange={handleLocaleChange}
      value={router.locale}
      size="lg"
      sx={{
        '@media screen and (max-width: 1200px)': {
          pl: 2,
          pr: 2,
          w: 'fit-content',
        },
        cursor: 'pointer',
        pl: 8,
      }}
    >
      <option value="en">English</option>
      <option value="fr">Français</option>
    </Select>
  );
}

export default LanguageSelector;
