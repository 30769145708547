import { BoxProps, Image } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';

import logo from './images/logo.png';
import styles from './Logo.module.css';

interface LogoProps extends BoxProps {
  boxSize?: string;
}

export const Logo = (props: LogoProps) => {
  const { boxSize = '100px' } = props;
  const t = useTranslations('Logo');

  return <Image alt={t('alt')} boxSize={boxSize} src={logo.src} />;
};

export default Logo;
