import { Box, HStack, Heading, Text } from '@chakra-ui/react';

import { CopyToClipboardIcon } from '../CopyToClipboardIcon/CopyToClipboardIcon';
import styles from './PromptOptimizeModalContent.module.css';

export interface IPromptOptimizeModalContentProps {
  content: string;
  title: string;
}

export function PromptOptimizeModalContent(
  props: IPromptOptimizeModalContentProps
) {
  const { content, title } = props;

  return (
    <Box className={styles.subsection}>
      <Heading as="h3" className={styles.subtitle}>
        {title}
      </Heading>
      <HStack className={styles.content}>
        <Text className={styles.text} whiteSpace="pre-line">
          {content}
        </Text>
        <CopyToClipboardIcon data={content} />
      </HStack>
    </Box>
  );
}

export default PromptOptimizeModalContent;
