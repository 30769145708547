import { Box, Button } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslations } from 'next-intl';

import { BillingInterval } from '@shared/util';

import styles from './BillingIntervalSelector.module.css';

export interface IBillingIntervalSelectorProps {
  billingInterval: string;
  setBillingInterval: Dispatch<SetStateAction<BillingInterval>>;
}

export const BillingIntervalSelector = (
  props: IBillingIntervalSelectorProps
) => {
  const { billingInterval, setBillingInterval } = props;
  const t = useTranslations('Subscribe');

  return (
    <Box display="flex" justifyContent="center" mb={6}>
      <Button
        onClick={() => setBillingInterval('month')}
        variant={billingInterval === 'month' ? 'primary' : 'secondary'}
        size="m"
        m={5}
      >
        {t('prices.month')}
      </Button>
      <Button
        onClick={() => setBillingInterval('year')}
        variant={billingInterval === 'year' ? 'primary' : 'secondary'}
        size="m"
        m={5}
      >
        {t('prices.year')}
      </Button>
    </Box>
  );
};

export default BillingIntervalSelector;
