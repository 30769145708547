interface IBaseError {
  category: string;
  code: string;
  details?: Record<string, unknown> | unknown;
  message: string;
  name: string;
}

interface IBaseErrorProps {
  category: string;
  code: string;
  details?: Record<string, unknown> | unknown;
  message: string;
}

export interface AuthenticationError extends IBaseError {
  category: 'Clerk' | 'Supabase';
}

interface IAuthenticationErrorProps extends IBaseErrorProps {
  category: 'Clerk' | 'Supabase';
}

export interface DatabaseError extends IBaseError {
  category: 'Supabase';
}

interface IDatabaseErrorProps extends IBaseErrorProps {
  category: 'Supabase';
}

export interface StripeError extends IBaseError {
  category: 'Config' | 'Customer' | 'Payment' | 'Product' | 'Subscription';
}

interface IStripeErrorProps extends IBaseErrorProps {
  category: 'Config' | 'Customer' | 'Payment' | 'Product' | 'Subscription';
}

export interface UnknownError extends IBaseError {
  category: 'Unknown';
}

interface IUnknownErrorProps extends IBaseErrorProps {
  category: 'Unknown';
}

export class AuthenticationError extends Error {
  constructor(props: IAuthenticationErrorProps) {
    const { category, code, details, message } = props;

    super(message);
    this.name = 'AuthenticationError';
    this.category = category;
    this.code = code;
    this.details = details;
  }
}

export class DatabaseError extends Error {
  constructor(props: IDatabaseErrorProps) {
    const { category, code, details, message } = props;

    super(message);
    this.name = 'DatabaseError';
    this.category = category;
    this.code = code;
    this.details = details;
  }
}

export class StripeError extends Error {
  constructor(props: IStripeErrorProps) {
    const { category, code, details, message } = props;

    super(message);
    this.name = 'StripeError';
    this.category = category;
    this.code = code;
    this.details = details;
  }
}

export class UnknownError extends Error {
  constructor(props: IUnknownErrorProps) {
    const { category, code, details, message } = props;

    super(message);
    this.name = 'UnknownError';
    this.category = category;
    this.code = code;
    this.details = details;
  }
}
