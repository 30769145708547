import {
  Button,
  Center,
  Tag as ChakraTag,
  HStack,
  IconButton,
  Input,
  TagCloseButton,
  TagLabel,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { useTranslations } from 'next-intl';

import { handleEnterKeyPress } from '@shared/util';
import { useTagsStore } from '@shared/data';

import styles from './Tag.module.css';

export interface ITag {
  id: number;
  name: string;
}
interface ITagProps {
  selected: boolean;
  tag: ITag;
}

export function Tag(props: ITagProps) {
  const { selected: isSelected, tag } = props;

  const deleteTag = useTagsStore((state) => state.deleteTag);
  const editTag = useTagsStore((state) => state.editTag);
  const selectTag = useTagsStore((state) => state.selectTag);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [tagName, setTagName] = useState<string>(props.tag.name);
  const t = useTranslations('Tag');

  const handleEditClick = () => setIsEditing(!isEditing);
  const handleSave = () => {
    editTag(tag.id, tagName);
    setIsEditing(false);
    selectTag(props.tag.name);
  };

  if (!tag) {
    return null;
  }

  if (isEditing) {
    return (
      <HStack mt={3}>
        <Center>
          <Input
            borderColor="primary.500"
            mr={2}
            onChange={(e) => setTagName(e.target.value)}
            onKeyPress={(event) =>
              handleEnterKeyPress(event, () => handleSave())
            }
            value={tagName}
          />
          <Button
            bg="primary.500"
            className={styles.editButton}
            color="white"
            onClick={() => handleSave()}
          >
            {t('save')}
          </Button>
        </Center>
      </HStack>
    );
  }

  return (
    <ChakraTag
      bg={isSelected ? 'primary.500' : 'white'}
      boxShadow="6px 6px 12px #cbced1, -6px -6px 12px #ffffff"
      key={tag.id}
      m={2}
      size="lg"
      variant="subtle"
    >
      <TagLabel
        color={isSelected ? 'white' : 'black'}
        cursor="pointer"
        onClick={() => selectTag(tag.name)}
      >
        {tag.name}
      </TagLabel>
      {isSelected && (
        <IconButton
          aria-label={t('edit')}
          bg="primary.500"
          className={styles.editIcon}
          color={isSelected ? 'white' : 'primary.500'}
          height={5}
          icon={<EditIcon />}
          minW={0}
          ml={3}
          onClick={() => handleEditClick()}
        />
      )}
      <TagCloseButton
        color={isSelected ? 'white' : 'primary.500'}
        onClick={() => deleteTag(tag.id)}
        opacity={1}
      />
    </ChakraTag>
  );
}

export default Tag;
