import * as Sentry from '@sentry/nextjs';
import { DatabaseError, ITags } from '@shared/util';
import { PostgrestResponse, SupabaseClient } from '@supabase/supabase-js';

export const createTag = async (
  supabase: SupabaseClient,
  userUUID: string,
  name: string
) => {
  const { data, error } = await supabase
    .from('tags')
    .insert([{ name: name, user_id: userUUID }])
    .select()
    .single();

  if (error) {
    Sentry.captureException(
      new DatabaseError({
        category: 'Supabase',
        code: 'DB0005',
        details: error,
        message: 'Error on creating tag',
      })
    );
  }

  return { data, error };
};

export const deleteTag = async (
  supabase: SupabaseClient,
  userUUID: string,
  tagId: number
) => {
  const { data, error } = await supabase
    .from('tags')
    .delete()
    .match({ id: tagId, user_id: userUUID });

  if (error) {
    Sentry.captureException(
      new DatabaseError({
        category: 'Supabase',
        code: 'DB0005',
        details: error,
        message: 'Error on deleting tag',
      })
    );
  }

  return { data, error };
};

export const editTag = async (
  supabase: SupabaseClient,
  userUUID: string,
  tagId: number,
  tagName: string
) => {
  const { data, error } = await supabase
    .from('tags')
    .update([{ name: tagName }])
    .eq('id', tagId)
    .eq('user_id', userUUID)
    .select()
    .single();

  if (error) {
    Sentry.captureException(
      new DatabaseError({
        category: 'Supabase',
        code: 'DB0005',
        details: error,
        message: 'Error on updating tag',
      })
    );
  }

  return { data, error };
};

export const getTags = async (supabase: SupabaseClient, userUUID: string) => {
  const { data, error } = (await supabase
    .from('tags')
    .select('id, name')
    .eq('user_id', userUUID)
    .order('name', {
      ascending: false,
    })) as PostgrestResponse<ITags>;

  if (error) {
    Sentry.captureException(
      new DatabaseError({
        category: 'Supabase',
        code: 'DB0005',
        details: error,
        message: 'Error on retrieving user tags',
      })
    );
  }

  return { data, error };
};
