import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  HStack,
  Input,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslations } from 'next-intl';

import { usePrompts, useTags } from '@shared/data';
import { CopyToClipboardIcon } from '@shared/ui';

import { PromptOptimizeModal } from '../PromptOptimizeModal/PromptOptimizeModal';
import { PromptOptimizeRunButton } from '../PromptOptimizeRunButton/PromptOptimizeRunButton';
import { PromptOptimizeUsage } from '../PromptOptimizeUsage/PromptOptimizeUsage';
import styles from './AddPromptForm.module.css';

export const AddPromptForm = () => {
  const t = useTranslations('Prompts');
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [prompt, setPrompt] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { createPrompt } = usePrompts();
  const { tags } = useTags();

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const handleTagChange = (selected: any) => {
    setSelectedTags(selected);
  };

  const handlePromptChange = (e: any) => {
    setPrompt(e.target.value);
  };

  const handleSave = async () => {
    setIsSubmited(true);
    if (name === '' || prompt === '') {
      return;
    }

    createPrompt({ name, prompt, tags: selectedTags });
    setSelectedTags([]);
    setName('');
    setPrompt('');
    setIsSubmited(false);
  };

  const isValidName = () => !isSubmited || (isSubmited && name !== '');

  const isValidPrompt = () => !isSubmited || (isSubmited && prompt !== '');

  return (
    <>
      <PromptOptimizeModal
        isOpen={isOpen}
        onClose={onClose}
        prompt={prompt}
        setPrompt={setPrompt}
      />
      <Box bg="white" borderRadius={16} minW={{ base: 0, lg: 600 }}>
        <Text className={styles.promptNameLabel}>{t('promptNameLabel')}</Text>
        <Input
          className={styles.promptNameInput}
          isInvalid={!isValidName()}
          onChange={handleNameChange}
          placeholder={t('promptName')}
          value={name}
        />
        <Text className={styles.promptTagsLabel}>{t('promptTagsLabel')}</Text>
        <CheckboxGroup
          colorScheme="primary"
          value={selectedTags}
          onChange={handleTagChange}
        >
          <HStack className={styles.promptTags} spacing={10} wrap="wrap">
            {tags ? (
              tags.map((tag) => (
                <Checkbox
                  className={styles.tag}
                  key={tag.id}
                  value={tag.id.toString()}
                  variant="circular"
                >
                  {tag.name}
                </Checkbox>
              ))
            ) : (
              <Box m="25px">{t('emptyMessages.noTag')}</Box>
            )}
          </HStack>
        </CheckboxGroup>
        <Text className={styles.promptFormLabel}>{t('promptFormLabel')}</Text>
        <Tabs colorScheme="primary" isFitted>
          <TabList>
            <Tab>{t('freePromptContent')}</Tab>
            <Tab>{t('managedPromptContent')}</Tab>
          </TabList>

          <TabPanels>
            <TabPanel className={styles.promptTabPanel}>
              <VStack spacing={4} align="stretch">
                <HStack>
                  <Textarea
                    isInvalid={!isValidPrompt()}
                    mr={5}
                    onChange={handlePromptChange}
                    placeholder={t('promptTextareaPlaceholder')}
                    value={prompt}
                  />
                  <CopyToClipboardIcon data={prompt} />
                </HStack>
                <HStack>
                  <Button
                    onClick={() => handleSave()}
                    type="submit"
                    variant="primary"
                  >
                    {t('save')}
                  </Button>
                  <Spacer />
                  <PromptOptimizeRunButton prompt={prompt} action={onOpen} />
                </HStack>
                <PromptOptimizeUsage />
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack spacing={4} align="stretch">
                <Text color="primary.500" mt={10} textAlign="center">
                  {t('soon')}
                </Text>
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default AddPromptForm;
