import { Button, Input } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslations } from 'next-intl';

import { handleEnterKeyPress } from '@shared/util';
import { useTagsStore } from '@shared/data';

import styles from './AddTagInput.module.css';

export const AddTagInput = () => {
  const t = useTranslations('Tags');
  const [tag, setTag] = useState('');
  const createTag = useTagsStore((state) => state.createTag);

  const addTag = (tag: string) => {
    createTag(tag);
    setTag('');
  };

  return (
    <>
      <Input
        borderColor="primary.500"
        mr={2}
        onChange={(e) => setTag(e.target.value)}
        placeholder={t('newTag')}
        value={tag}
        onKeyPress={(event) => handleEnterKeyPress(event, () => addTag(tag))}
      />
      <Button
        bg="primary.500"
        className={styles.addButton}
        color="white"
        onClick={() => addTag(tag)}
      >
        {t('addTag')}
      </Button>
    </>
  );
};

export default AddTagInput;
