import { create } from 'zustand';

import { IUserLimits, IUserStats } from '@shared/util';

interface IUserStore {
  isAddPromptEnabled: boolean;
  isOptimizePromptEnabled: boolean;
  limits: IUserLimits;
  remainingOptimizations: number;
  stats: IUserStats;

  // Actions
  setCapabilities: () => void;
  setLimits: (limits: IUserLimits) => void;
  setStats: (stats: IUserStats) => void;
}

export const useUserStore = create<IUserStore>()((set, get) => ({
  isAddPromptEnabled: false,
  isOptimizePromptEnabled: false,
  limits: {
    chats: 0,
    optimizations: 0,
    prompts: 0,
    tags: 0,
  },
  remainingOptimizations: 0,
  setCapabilities: () => {
    const limits = get().limits;
    const stats = get().stats;

    set({
      isAddPromptEnabled:
        limits.prompts === 'inf' || stats.prompts < (limits.prompts as number),
      isOptimizePromptEnabled: stats.optimizations < limits.optimizations,
      remainingOptimizations: limits.optimizations - stats.optimizations,
    });
  },
  setLimits: (limits) => {
    set({ limits });
    get().setCapabilities();
  },
  setStats: (stats) => {
    set({ stats });
    get().setCapabilities();
  },
  stats: {
    chats: 0,
    optimizations: 0,
    prompts: 0,
    tags: 0,
  },
}));

export default useUserStore;
